import http, { ResultData } from 'src/utilities/http'
import { Key } from 'react'
import axios from 'axios'
import { groupBy } from 'lodash-es'
import { storageGet, storageSet } from 'src/utilities'

/**
 * 游戏平台分组
 */
export interface GamePlatGroupProps {
  id: number // 分组ID
  gid: number // 游戏ID
  name: string // 分组名
}

/**
 * 游戏平台
 */
export interface GamePlatProps {
  gid: number // 游戏ID
  pid: number // 平台ID
  name: string // 平台名
  tag: string // 平台标识
  group: number // 分组ID
  order?: number // 排序值
  state?: number // 状态(1=暂停)
  server_label: string // 默认区服标签
  sign_cfg: string // 默认签名配置
  result_cfg: string // 默认返回结果配置
}

/**
 * 游戏平台树结构属性
 *
 * @export
 * @interface GamePlatTreeProps
 */
export interface GamePlatTreeProps {
  id: number
  name: string
  children: GamePlatProps[]
}

/**
 * 游戏平台管理
 */
const platform = {
  /**
   * 获取游戏平台树（分组=>平台）列表
   *
   * @param {Key} gameId - 游戏ID
   */
  tree(gameId: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      axios
        .all([this.group_list(gameId), this.list(gameId, undefined, 0)])
        .then(
          axios.spread((groupResult, platformResult) => {
            if (groupResult.code === 1 && platformResult.code === 1) {
              const groups: GamePlatGroupProps[] = groupResult.data || []
              const platforms: GamePlatProps[] = platformResult.data || []
              let groupPlatforms = groupBy(platforms, 'group')
              let data: GamePlatTreeProps[] = []
              groups.forEach((el) => {
                data.push({
                  id: el.id,
                  name: el.name,
                  children: groupPlatforms[el.id] || []
                })
              })

              if (data.length !== 0) {
                storageSet(`platforms_${gameId}`, JSON.stringify(data))
              }

              resolve({
                code: 1,
                data: data
              })
            } else {
              resolve({
                code: 0,
                data: []
              })
            }
          })
        )
        .catch((reason) => reject(reason))
    })
  },

  /**
   * 获取游戏平台树（分组=>平台）列表，优先从Storage获取
   *
   * @param {Key} gameId
   * @returns
   */
  cacheTree(gameId: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      let platformsJson = storageGet(`platforms_${gameId}`)
      if (platformsJson) {
        let platforms = JSON.parse(platformsJson)
        resolve({
          code: 1,
          data: platforms
        })
      } else {
        return this.tree(gameId)
          .then((result) => {
            let data = result.data || []
            resolve({
              code: result.code,
              data: data,
              msg: result.msg
            })
          })
          .catch((e) => reject(e))
      }
    })
  },

  /**
   * 获取游戏平台列表
   *
   * @param {Key} gameId - 游戏ID
   * @param {number} [groupId] - 平台分组ID
   * @param {number} [state] - 状态(1为暂停)[单纯获取操作列表时传0]
   * @returns
   */
  list(gameId: Key, groupId?: number, state?: number) {
    return http.get(`/games/plats/plat/${gameId}`, {
      params: {
        group: groupId,
        state: state
      }
    })
  },

  /**
   * 获取游戏平台详情
   *
   * @param {Key} gameId - 游戏ID
   * @param {number} [pid] - 平台ID
   * @returns
   */
  get(gid: Key, pid: number) {
    return http.get(`/games/plats/plat/${gid}/${pid}`)
  },

  /**
   * 新增
   *
   * @param {GamePlatProps} data - 表单数据
   * @returns
   */
  add(data: GamePlatProps) {
    return http.post(`/games/plats/plat/${data.gid}`, data)
  },

  /**
   * 修改
   *
   * @param {GamePlatProps} data - 表单数据
   * @returns
   */
  update(pid: Key, data: GamePlatProps) {
    return http.put(`/games/plats/plat/${data.gid}/${pid}`, data)
  },

  /**
   * 删除
   *
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  delete(gid: number, pid: number) {
    return http.delete(`/games/plats/plat/${gid}/${pid}`)
  },

  /**
   * 获取平台分组列表
   *
   * @param {Key} gameId - 游戏ID
   * @returns
   */
  group_list(gameId: Key) {
    return http.get(`/games/plats/group/${gameId}`)
  },

  /**
   * 新增分组
   *
   * @param {GameGroupProps} data - 表单数据
   * @returns
   */
  group_add(data: GamePlatGroupProps) {
    return http.post(`/games/plats/group/${data.gid}`, data)
  },

  /**
   * 修改分组
   *
   * @param {GameGroupProps} data - 表单数据
   * @returns
   */
  group_update(data: GamePlatGroupProps) {
    return http.put(`/games/plats/group/${data.gid}/${data.id}`, data)
  },

  /**
   * 删除分组
   *
   * @param {number} gameId - 游戏ID
   * @param {number} groupId - 分组ID
   * @returns
   */
  group_delete(gameId: number, groupId: number) {
    return http.delete(`/games/plats/group/${gameId}/${groupId}`)
  }
}

export default platform
