import http from 'src/utilities/http'
import { SorterProps, PagingProps, SearchParamProps } from '..'
import { Key } from 'react'

/**
 * 属性
 */
export interface WaiterProps {
  id: number //配置ID
  type: number //类型(位)(EFlowType(除User外))
  //
  gid: number // 游戏ID 默认-1（通用）
  pid: number // 平台ID 默认-1（通用）
  //
  uid: number // 用户ID
  uname: string // 用户名(根据uid获取)
  nick: string // 昵称(可单独设置客服名)
  desc: string //描述说明
  //
  pname: string // 平台名
}

/**
 * 工单客服管理
 */
const waiterApi = {
  /**
   * 获取列表(uid=自己时，只查自己列表)
   *
   * 需要权限: workorder_waiter_list
   *
   * @param {(WaiterProps | PagingProps | SorterProps | SearchParamProps | expert:bool)} [params] - 搜索条件
   * @returns
   */
  list(params?: WaiterProps | PagingProps | SorterProps | SearchParamProps) {
    return http.get('/workorder/waiter', {
      params
    })
  },

  /**
   * 根据游戏ID和平台ID获取客服列表
   *
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  listByGidPid(gid: number, pid: number) {
    return http.get('/workorder/waiter/expert', {
      params: {
        gid,
        pid
      }
    })
  },

  /**
   * 获取自己的客服列表
   *
   * @returns
   */
  self() {
    return http.get('/workorder/waiter/self')
  },

  /**
   * 获取详情
   *
   * 需要权限: workorder_waiter_list
   *
   * @param {Key} id - 配置
   * @returns
   */
  get(id: Key) {
    return http.get(`/workorder/waiter/${id}`)
  },

  /**
   * 新增
   *
   * 需要权限: workorder_waiter_add
   *
   * @param {WaiterProps} data - 表单数据
   * @returns
   */
  add(data: WaiterProps) {
    return http.post(`/workorder/waiter`, data)
  },

  /**
   * 修改
   *
   * 需要权限: workorder_waiter_update
   *
   * @param {WaiterProps} data - 表单数据
   * @returns
   */
  update(data: WaiterProps) {
    return http.put(`/workorder/waiter/${data.id}`, data)
  },

  /**
   * 删除
   *     可根据配置id删除一条
   *     或根据gid,pid删除多条,可根据uid删除多条
   *
   * 需要权限: workorder_waiter_del
   *
   * @param {number} id - 配置ID
   * --------
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * --------
   * @param {number} uid - 用户ID
   * @returns
   */
  delete(id?: number, gid?: number, pid?: number, uid?: number) {
    return http.delete(`/workorder/waiter`, {
      params: {
        id,
        gid,
        pid,
        uid
      }
    })
  }
}

export default waiterApi
