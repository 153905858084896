import http from 'src/utilities/http'
import { Key } from 'react'
import { SorterProps, PagingProps } from '..'

export interface EnvFieldSysProps {
  id: number
  field: string // 字段名
  name: string // 中文名
  desc?: string // 描述
}

/**
 * 通用字段管理
 */
const envFieldSys = {
  /**
   * 获取通用字段列表
   */
  list() {
    return http.get('/envs/fields/sys', {
      params: {
        list: true
      }
    })
  },
  /**
   * 获取通用字段列表
   */
  page(params?: EnvFieldSysProps | SorterProps | PagingProps) {
    return http.get('/envs/fields/sys', {
      params
    })
  },
  /**
   * 获取
   *
   * @param {number} id - 表单数据
   * @returns
   */
  get(id: number) {
    return http.post(`/envs/fields/sys/${id}`)
  },
  /**
   * 新增通用字段
   *
   * @param {EnvFieldSysProps} data - 表单数据
   * @returns
   */
  add(data: EnvFieldSysProps) {
    return http.post('/envs/fields/sys', data)
  },

  /**
   * 修改通用字段
   *
   * @param {EnvFieldSysProps} data - 表单数据
   * @returns
   */
  update(data: EnvFieldSysProps) {
    return http.put(`/envs/fields/sys/${data.id}`, data)
  },

  /**
   * 删除通用字段
   *
   * @param {Key} id - ID
   * @returns
   */
  delete(id: Key) {
    return http.delete(`/envs/fields/sys/${id}`)
  }
}

export default envFieldSys
