import { createContext } from 'react'
import { PlatType } from 'src/api'

interface Permit {
  [k: string]: any
}

interface PermitProps {
  [k: string]: Permit
}

/**
 * 用户权限
 */
export const permits: PermitProps = {
  [PlatType.Auth]: {},
  [PlatType.Kernel]: {},
  [PlatType.Gm]: {}
}

/**
 * 设置权限
 *
 * @export
 * @param {PlatType} plat - 分组
 * @param {any[]} data - 权限数据
 */
export function setPermits(plat: PlatType, data: any[]) {
  let pms = permits[plat]
  data.forEach((item: any) => {
    pms[item.name] = item.haves || true
  })
}

/**
 * 是否拥有权限
 *
 * @export
 * @param {string} name - 权限名
 * @param {number} [gid] - 游戏ID
 * @param {number} [pid] - 平台ID
 * @returns
 */
export function hasPermit(name: string, gid?: number, pid?: number) {
  let names = name.split('.')
  let permit = permits[names[0]][names[1]]
  let superAdmin = permits[PlatType.Auth]['admin'] // 超级管理员
  let subAdmin // 子管理员
  let moduleAdmin = permits[names[0]]['admin'] // 模块管理员
  switch (names[0]) {
    case PlatType.Gm:
      subAdmin = permits[PlatType.Auth]['gm_admin']
      break
    case PlatType.Kernel:
      subAdmin = permits[PlatType.Auth]['kernel_admin']
      break
    default:
      break
  }

  if (superAdmin || subAdmin) {
    return true
  }

  if (gid) {
    if (pid) {
      if (
        (moduleAdmin &&
          ((moduleAdmin['-1'] && moduleAdmin['-1'].some((el: number) => el === -1 || el === pid)) ||
            (moduleAdmin[gid] && moduleAdmin[gid].some((el: number) => el === -1 || el === pid)))) ||
        (permit && 
          ((permit['-1'] && permit['-1'].some((el: number) => el === -1 || el === pid)) ||
          (permit[gid] && permit[gid].some((el: number) => el === -1 || el === pid))))
      ) {
        return true
      }
      return false
    }
    return (permit && (permit['-1'] || permit[gid])) || (moduleAdmin && (moduleAdmin['-1'] || moduleAdmin[gid]))
  }
  return !!permit || !!moduleAdmin
}

const PermitContext = createContext<PermitProps>(permits)

export default PermitContext
