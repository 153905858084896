import {
  HomeOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
  CodeOutlined,
  SettingOutlined,
  GlobalOutlined,
  ToolOutlined,
  NotificationOutlined,
  BugOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'

function CustomIcon({ type }: { type: string }) {
  switch (type) {
    case 'HomeOutlined':
      return <HomeOutlined />
    case 'UserOutlined':
      return <UserOutlined />
    case 'SafetyCertificateOutlined':
      return <SafetyCertificateOutlined />
    case 'CodeOutlined':
      return <CodeOutlined />
    case 'NotificationOutlined':
      return <NotificationOutlined />
    case 'SettingOutlined':
      return <SettingOutlined />
    case 'GlobalOutlined':
      return <GlobalOutlined />
    case 'ToolOutlined':
      return <ToolOutlined />
    case 'BugOutlined':
      return <BugOutlined />
    case 'UnorderedListOutlined':
      return <UnorderedListOutlined />
    default:
      return null
  }
}

export default CustomIcon
