import http from 'src/utilities/http'
import { Key } from 'react'

/**
 * 执行接口配置
 *
 * @export
 * @interface CommunicationProps
 */
export interface CommunicationProps {
  gm_game_id: Key // 游戏ID
  gm_plat_id: Key // 平台ID
  gm_server_id: Key // 区服ID
  gm_name: string // 游戏接口名
}

/**
 * 游戏通信指令
 */
const communication = {
  send(params: CommunicationProps, data: any) {
    return http.post('/gm/communication/sendMessage', data, {
      params
    })
  }
}

export default communication
