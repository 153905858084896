import http, { ResultData } from 'src/utilities/http'
import { SorterProps, PagingProps, PlatType, getOrigin, DictProps } from '..'
import { storageGet, storageSet } from 'src/utilities'
import { Key } from 'react'

/**
 * 获取当前接口URL
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * 游戏状态列表
 */
export const GameStates: DictProps[] = [
  { id: 0, name: '正式' },
  { id: 1, name: '测试' },
  { id: 2, name: '暂停' },
  { id: 3, name: '关闭' }
]

/**
 * 游戏列表
 *
 * @export
 * @interface GameProps
 */
export interface GameProps {
  id: number //游戏ID
  name?: string //游戏名
  type?: string //游戏类型
  desc?: string //游戏说明
  logo?: string //游戏微标
}

/**
 * 游戏列表管理
 */
const game = {
  /**
   * 分页获取游戏列表
   *
   * @param {(GameProps | SorterProps | PagingProps)} [params] - 搜索条件
   * @returns
   */
  page(params?: GameProps | SorterProps | PagingProps) {
    return http.get('/games/tags', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取所有游戏列表
   *
   * @returns
   */
  list() {
    let params = { page_size: 9999 }
    return new Promise<ResultData>((resolve, reject) => {
      this.page(params)
        .then((result) => {
          let data = (result.data && result.data.data) || []
          resolve({
            code: result.code,
            data: data,
            msg: result.msg
          })
          if (data.length !== 0) {
            storageSet('games', JSON.stringify(data))
          }
        })
        .catch((e) => reject(e))
    })
  },

  /**
   * 获取游戏列表，优先从Storage获取
   *
   * @returns
   */
  cacheList() {
    return new Promise<ResultData>((resolve, reject) => {
      let gamesJson = storageGet('games')
      if (gamesJson) {
        let games = JSON.parse(gamesJson)
        resolve({
          code: 1,
          data: games
        })
      } else {
        this.list()
          .then((result) => resolve(result))
          .catch((e) => reject(e))
      }
    })
  },

  /**
   * 获取详情
   *
   * @param {number} id - 角色ID
   * @returns
   */
  get(id: number) {
    return http.get(`/games/tags/${id}`, {
      baseURL: baseURL()
    })
  },

  /**
   * 新增
   *
   * @param {GameProps} data - 表单数据
   * @returns
   */
  add(data: GameProps) {
    return http.post('/games/tags', data, {
      baseURL: baseURL()
    })
  },

  /**
   * 修改
   *
   * @param {Key} oldId - 原始数据ID
   * @param {GameProps} data - 表单数据
   * @returns
   */
  update(oldId: Key, data: GameProps) {
    return http.put(`/games/tags/${oldId}`, data, {
      baseURL: baseURL()
    })
  },
  /**
   * 删除
   *
   * @param {number} id - 角色ID
   * @returns
   */
  delete(id: number) {
    return http.delete(`/games/tags/${id}`, {
      baseURL: baseURL()
    })
  }
}

export default game
