import { createContext } from 'react'
import { UserProps } from 'src/api/auth/self'

interface User {
  info: UserProps
}

/**
 * 用户信息、用户权限
 */
export const userStore = {
  info: {}
}

// 用户操作reducer
export function userReducer(state: User, action: any) {
  switch (action.type) {
    case 'setInfo':
      return {
        ...state,
        info: action.payload
      }
    default:
      throw new Error()
  }
}

const UserContext = createContext<User>(userStore)

export default UserContext
