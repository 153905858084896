import { Key } from 'react'
import self from './auth/self'

// 玩家管理
import player from './player/player'
import playerRole from './player/role'

// 权限
import permit from './permits/permit'
import permitUser from './permits/permit_user'
import permitRole from './permits/permit_role'

// 用户
import users from './auth/users'

// 游戏配置
import platform from './game/platform'
import server from './game/server'
import serverRelation from './game/server_relation'
import gameApi from './game/game_api'
import gameData from './game/game_data'
import autoTask from './game/auto_task'
import gameItem from './game/item'

// 字段配置管理
import envFieldSys from './field/env_field_sys'
import envFieldGame from './field/env_field_game'
import exclusiveField from './field/exclusive_field'

// 游戏通讯指令
import communication from './communication/communication'

// 工单系统
import workorder from './workorder/workorder'
import faq from './workorder/faq'
import keep from './workorder/keep'
import waiter from './workorder/waiter'
import domain from './workorder/domain'

// 日志记录
import log from './log/log'
import gameLog from './log/game_log'

// 全局配置
import game from './kernel/game'
import gameType from './kernel/games_types'
import serverLabel from './kernel/servers_labels'
import kernelPermit from './kernel/permit'
import kernelPermitRole from './kernel/permit_role'
import kernelPermitUser from './kernel/permit_user'

/**
 * 搜索参数通用，避免数据表Model不能为空提示
 *
 * @export
 * @interface SearchParamProps
 */
export interface SearchParamProps {
  [k: string]: any
}

/**
 * 排序
 *
 * @export
 * @interface SorterProps
 */
export interface SorterProps {
  order_field?: Key | Key[]
  order_by?: 'asc' | 'desc' | ''
}

/**
 * 分页
 *
 * @export
 * @interface PagingProps
 */
export interface PagingProps {
  page_num?: number
  page_size?: number
}

/**
 * 通用字典
 *
 * @export
 * @interface DictProps
 */
export interface DictProps {
  id: number
  name: string
}

/**
 * 平台类型
 *  */
export enum PlatType {
  /**
   * 用户鉴权
   */
  Auth = 'Auth',
  /**
   * 全局配置
   */
  Kernel = 'Kernel',
  /**
   * 游戏管理
   */
  Gm = 'Gm'
}

/**
 * 获取平台URL
 *
 * @export
 * @param {PlatType} plat - 平台
 * @returns
 */
export function getOrigin(plat: PlatType) {
  switch (plat) {
    case PlatType.Auth:
      return `${import.meta.env.VITE_ORIGIN_AUTH}`
    case PlatType.Kernel:
      return `${import.meta.env.VITE_ORIGIN_KERNEL}`
    case PlatType.Gm:
      return `${import.meta.env.VITE_ORIGIN_GM}`
  }
}

const api = {
  self,
  platform,
  server,
  serverRelation,
  autoTask,
  player,
  playerRole,
  permit,
  permitUser,
  permitRole,
  users,
  gameApi,
  gameData,
  gameItem,
  envFieldSys,
  envFieldGame,
  exclusiveField,
  communication,
  workorder,
  faq,
  keep,
  waiter,
  domain,
  log,
  gameLog,

  game,
  gameType,
  serverLabel,
  kernelPermit,
  kernelPermitRole,
  kernelPermitUser
}

export default api
