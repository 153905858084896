import { lazy } from 'react'
import { RouterProps } from '.'

const routes: RouterProps[] = [
  {
    path: '/global_cfg',
    text: '全局配置',
    icon: 'GlobalOutlined',
    exact: true,
    pms: 'Kernel.menu_config',
    routes: [
      {
        path: '/global_cfg/game',
        text: '游戏列表',
        exact: true,
        pms: 'Kernel.GameTag_Get',
        component: lazy(() => import('src/views/Global/Config/GameList'))
      },
      {
        path: '/global_cfg/game_type',
        text: '游戏类型',
        exact: true,
        pms: 'Kernel.GameType_Get',
        component: lazy(() => import('src/views/Global/Config/GameType'))
      },
      {
        path: '/global_cfg/server_label',
        text: '区服标签',
        exact: true,
        pms: 'Kernel.GameServerLabel_Get',
        component: lazy(() => import('src/views/Global/Config/ServerLabel'))
      }
    ]
  },
  {
    path: '/global_permit',
    text: '权限管理',
    icon: 'SafetyCertificateOutlined',
    exact: true,
    pms: 'Kernel.menu_permit_manager',
    routes: [
      {
        path: '/global_permit/list',
        text: '权限列表',
        exact: true,
        pms: 'Kernel.permit_list',
        component: lazy(() => import('src/views/Global/Permit/List'))
      },
      {
        path: '/global_permit/role',
        text: '角色列表',
        exact: true,
        pms: 'Kernel.permit_role_list',
        component: lazy(() => import('src/views/Global/Permit/Role'))
      },
      {
        path: '/global_permit/authorize',
        text: '用户授权',
        exact: true,
        pms: 'Kernel.permit_user_list',
        component: lazy(() => import('src/views/Global/Permit/Authorize'))
      }
    ]
  }
]

export default routes
