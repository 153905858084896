import { useState, useContext, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import 'moment/locale/zh-cn'
import zhCN from 'antd/es/locale/zh_CN'
import { CSPConfig } from 'antd/es/config-provider'
import './App.less'
import BasicLayout from 'src/components/BasicLayout'
import TokenContext from 'src/store/TokenContext'
import { setPermits } from 'src/store/PermitContext'
import api, { PlatType } from 'src/api'
import { isEmpty } from 'lodash-es'
import Loading from 'src/components/Loading'
import The403 from 'src/components/The403'
import { PermitProps } from 'src/api/permits/permit'
import Axios from 'axios'

const csp: CSPConfig = {
  nonce: "default-src 'self' *.mlplat.com"
}

function App() {
  const [loading, setLoading] = useState(true)
  const [hasPms, setHasPms] = useState(false)

  const token = useContext(TokenContext)

  // 拉取用户在授权中心的权限
  const fetchAuthPms = useCallback(() => {
    api.self
      .authPermits()
      .then((result) => {
        if (result.code === 1) {
          let data = result.data || []
          if (!isEmpty(data) && data.some((el: PermitProps) => el.name === 'gm_auth' || el.name === 'kernel_auth')) {
            setHasPms(true)
            setPermits(PlatType.Auth, data)
            Axios.all([fetchGmPms(), fetchKernelPms()]).then(() => {
              setLoading(false)
            })
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }, [])

  // 拉取用户在GM的权限
  function fetchGmPms() {
    return api.self.permits().then((result) => {
      if (result.code === 1) {
        if (!isEmpty(result.data)) {
          setPermits(PlatType.Gm, result.data)
        }
      }
    })
  }

  // 拉取用户在Kernel的权限
  function fetchKernelPms() {
    return api.self.kernelPermits().then((result) => {
      if (result.code === 1) {
        if (!isEmpty(result.data)) {
          setPermits(PlatType.Kernel, result.data)
        }
      }
    })
  }

  useEffect(() => {
    // 没有Token，跳到登录页
    if (!token.token) {
      let redirect = encodeURIComponent(window.location.href)
      window.location.href = `${import.meta.env.VITE_WEB_USER}/signin?redirect=${redirect}`
      return
    }

    fetchAuthPms()
  }, [token.token, fetchAuthPms])

  return (
    <>
      {loading ? (
        <Loading />
      ) : hasPms ? (
        <Router>
          <ConfigProvider locale={zhCN} csp={csp}>
            <Switch>
              <Route path="/" component={BasicLayout} />
            </Switch>
          </ConfigProvider>
        </Router>
      ) : (
        <The403 />
      )}
    </>
  )
}

export default App
