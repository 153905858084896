/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { AxiosRequestConfig } from 'axios'
import { message } from 'antd'
import { isRefreshExpired, isExpired } from '.'
import { token } from 'src/store/TokenContext'
import api from 'src/api'

/**
 * 返回数据格式
 *
 * @interface ResultData
 *
 */
export interface ResultData {
  code: number
  data: any
  msg?: string
  page_size?: any
  page_num?: any
  page_total?: any
}

// 创建axios实例
const instance = Axios.create({
  // 基础url前缀
  baseURL: `${import.meta.env.VITE_ORIGIN_GM}`,

  // 设置超时时间
  timeout: 30000
})

// 请求拦截器
instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let auth = token.token

    // 不需要权限验证的接口
    const noAuthUrls = ['/auth/login', '/auth/register', '/auth/refresh']

    // 判断Token是否过期
    if (!noAuthUrls.includes(config.url || '') && isExpired()) {
      let redirect = encodeURIComponent(window.location.href)
      let url = `${import.meta.env.VITE_WEB_USER}/signin?redirect=${redirect}`

      // 判断刷新token是否过期
      if (isRefreshExpired()) {
        message.error('登录过期')
        token.removeToken()
        window.location.href = url
      } else {
        let refreshResult = await api.self.refreshToken(token.tokenRefresh)
        if (refreshResult.code === 1) {
          token.refreshToken(refreshResult.data)
          auth = refreshResult.data.token
        } else {
          token.removeToken()
          window.location.href = url
        }
      }
    }

    if (config.method && config.method.toLowerCase() === 'get') {
      let time = new Date().getTime()
      if (config.params) {
        config.params._ = time
      } else {
        config.params = {
          _: time
        }
      }
    }
    auth && (config.headers.Authorization = auth)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    let contentType = response.headers['content-type']
    if (!contentType || contentType.indexOf('application/json') !== -1) {
      if (response.config.method === 'get') {
        if (response.config.url !== '/game/config/detail' && response.data.code !== 1) {
          message.error(`请求失败：${response.data.msg || ''} ${response.config.url}`)
        }
      }
      return response.data
    } else {
      return {
        code: 1,
        data: response
      }
    }
  },
  (error) => {
    let response = error.response
    if (!response) {
      message.error('请求网络错误')
      return Promise.reject(error)
    }
    switch (response.status) {
      case 400:
        message.error(`请求错误：${response.request.responseURL}`)
        break

      case 401:
        let redirect = encodeURIComponent(window.location.href)
        let url = `${import.meta.env.VITE_WEB_USER}/signin?redirect=${redirect}`

        // 判断刷新Token是否过期
        if (isRefreshExpired()) {
          message.error('未授权，请登录')
          token.removeToken()

          window.location.href = url
        } else {
          api.self.refreshToken(token.tokenRefresh).then((result) => {
            if (result.code === 1) {
              token.refreshToken(result.data)
              window.location.reload()
            } else {
              token.removeToken()
              window.location.href = url
            }
          })
        }
        break

      case 403:
        message.error('拒绝访问')
        break

      case 404:
        message.error(`找不到请求地址：${response.config.url}`)
        break

      case 405:
        message.error(`HTTP方法不被允许`)
        break

      case 408:
        message.error('请求超时')
        break

      case 500:
        message.error('服务器内部错误')
        break

      case 501:
        message.error('服务未实现')
        break

      case 502:
        message.error('网关错误')
        break

      case 503:
        message.error('服务不可用')
        break

      case 504:
        message.error('网关超时')
        break

      case 505:
        message.error('HTTP版本不受支持')
        break

      default:
        message.error(`错误信息：${response.data}`)
    }
    return Promise.reject(error)
  }
)

export interface Http {
  request<T = any, R = ResultData>(config: AxiosRequestConfig): Promise<R>
  get<T = any, R = ResultData>(url: string, config?: AxiosRequestConfig): Promise<R>
  delete<T = any, R = ResultData>(url: string, config?: AxiosRequestConfig): Promise<R>
  head<T = any, R = ResultData>(url: string, config?: AxiosRequestConfig): Promise<R>
  options<T = any, R = ResultData>(url: string, config?: AxiosRequestConfig): Promise<R>
  post<T = any, R = ResultData>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
  put<T = any, R = ResultData>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
  patch<T = any, R = ResultData>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>
}

const http: Http = {
  request: instance.request,
  get: instance.get,
  delete: instance.delete,
  head: instance.head,
  options: instance.options,
  post: instance.post,
  put: instance.put,
  patch: instance.patch
}

export default http
