import { createContext } from 'react'

interface WorkOrder {
  count: number
}

/**
 * 工单带领取数量
 */
export const workOrderStore = {
  count: 0
}

// 用户操作reducer
export function workOrderReducer(state: WorkOrder, action: any) {
  switch (action.type) {
    case 'setCount':
      return {
        count: action.payload
      }
    default:
      throw new Error()
  }
}

const WorkOrderContext = createContext<WorkOrder>(workOrderStore)

export default WorkOrderContext
