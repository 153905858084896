import http from 'src/utilities/http'
import { SorterProps, PagingProps, SearchParamProps } from '..'
import { Key } from 'react'
import { EnumDataType } from '../field/exclusive_field'

/**
 * 游戏接口属性
 */
export interface GameApiProps {
  id: number
  gid: number // 游戏ID 必须
  pid: number // 平台ID 默认-1（全部）
  name: string // 接口枚举 必须
  permit: string // 接口权限
  url: string // 接口地址 必须
  desc: string // 接口用途 必须
  method: string // 请求方式  必须
  time_unit: number // 时间单位，默认：秒
  domain?: string // 请求域名

  /**
   * 请求参数 JSON格式，fields是字段列表，switch是字段映射
   * 例：{"fields":[{"body":0,"field":"gid","name":"游戏ID","show":1,"formComponent":"Input","defaultValue":"","source":""}],"switch":""}
   * source数据源为JSON格式 如：[{"value":0,"text":"否"},{"value":1,"text":"是"}]
   * source数据源在formComponent为DatePicker日期控件时，使用格式 {"showtime":0,"unit":1000,"timestamp":0}
   */
  params?: string
  c_params?: string // 请求公共参数 JSON格式 例：["key1","key2","key3"]
  r_fields?: string // 返回字段 JSON格式 例：[{"source":"normal","field":"sid","name":"区服ID","type":"number"},{"source":"exclusive","exclusive_id":"1","api_id":"1"}]
  r_fields_key?: string // 返回字段列表数据主键
  result_cfg?: string // 返回结构配置 JSON格式
  format_cfg?: string // 格式化配置 JSON格式
  sign_cfg?: string // 签名相关配置 JSON格式
}

// sign_uri,           //接口地址是否参与签名(默认null/false)
// sign_method,     //请求方式参与签名(默认null/false)
// sign_body,         //PostBody参与签名(默认null/false)
// encode_body,   //Body编码方式(默认null/-1)[-1:不编码,1:Base64编码]
// prefix_method, //请求方式参与签名时：方法前缀
// prefix_param,   //参数前缀(默认null/"")
// prefix_body,     //PostBody参与签名时：包体前缀(默认null/"")

/**
 * 游戏接口名属性
 *
 * @export
 * @interface GameApiNameProps
 */
export interface GameApiNameProps {
  name: string // 名称
  desc: string // 描述
}

/**
 * 表单控件枚举
 *
 * @export
 */
export enum EnumFormComponent {
  Input = 'Input',
  InputNumber = 'InputNumber',
  TextArea = 'TextArea',
  Select = 'Select',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  DatePicker = 'DatePicker',
  Static = 'Static',
  Modal = 'Modal'
}

// 表单控件列表
export const formComponents: GameApiNameProps[] = [
  { name: 'Input', desc: '输入框' },
  { name: 'InputNumber', desc: '数字输入框' },
  { name: 'TextArea', desc: '文本域' },
  { name: 'Select', desc: '选择器' },
  { name: 'Radio', desc: '单选框' },
  { name: 'Checkbox', desc: '多选框' },
  { name: 'DatePicker', desc: '日期选择框' },
  { name: 'Static', desc: '静态文本' },
  { name: 'Modal', desc: '弹窗' }
]

/**
 * 游戏接口参数属性
 *
 * @export
 * @interface GameApiParamProps
 */
export interface GameApiParamProps {
  body: number // Body参数 0：URL传参 1：body传参
  field: string // 字段
  name: string // 中文
  show: number // 页面显示 0：否、1：是
  required: number // 是否必填 0：否、1：是
  formComponent: EnumFormComponent // 组件名
  defaultValue: string | number // 默认值
  source: any[] | any // 数据源
}

/**
 * 游戏接口结果属性
 *
 * @export
 * @interface GameApiResultProps
 */
export interface GameApiResultProps {
  source: EnumGameApiResultSource // 来源
  field?: string // 字段
  name?: string // 中文
  type?: EnumDataType // 数据类型
  exclusive_id?: string // 关联专属字段ID
  api_name?: string // 关联游戏接口名
}

export interface GameApiFormatProps {
  field: string // 字段
  name: string // 中文
  apply: number // 是否序列化 0：否、1：是
  required: number // 是否必填 0：否、1：是
  formComponent: EnumFormComponent // 组件名
  defaultValue: string | number // 默认值
  source: any[] | any // 数据源
}

/**
 * 字段数据类型枚举
 *
 * @export
 */
export enum EnumGameApiResultSource {
  Normal = 'normal', // 普通类型
  Exclusive = 'exclusive' // 关联专属字段
}

/**
 * 游戏接口枚举
 */
export enum EnumGameApiName {
  /* 后台有用到，不要修改名称 */

  /**
   * 游戏区服列表
   */
  GameZone = 'GameZone',
  /**
   * 游戏道具列表
   */
  ItemList = 'ItemList',
  /**
   * 帐号列表
   */
  AccountList = 'AccountList',
  /**
   * 角色列表
   */
  RoleList = 'RoleList',
  /**
   * 游戏日志列表
   */
  GameLog = 'GameLog',

  /* 前端自定义 */

  /**
   * 帐号列表
   */
  // AccountList = 'AccountList',
  /**
   * 帐号封禁列表
   */
  AccountFreezeList = 'AccountFreezeList',
  /**
   * 帐号禁言列表
   */
  AccountGagList = 'AccountGagList',
  /**
   * 帐号封禁/解封
   */
  AccountFreeze = 'AccountFreeze',
  /**
   * 帐号禁言/解禁
   */
  AccountGag = 'AccountGag',
  /**
   * 帐号白名单列表
   */
  AccountWhitelist = 'AccountWhitelist',
  /**
   * 帐号白名单新增
   */
  AccountWhitelistAdd = 'AccountWhitelistAdd',
  /**
   * 帐号白名单修改
   */
  AccountWhitelistUpdate = 'AccountWhitelistUpdate',
  /**
   * 帐号白名单移除
   */
  AccountWhitelistDelete = 'AccountWhitelistDelete',

  /**
   * 角色列表
   */
  // RoleList = 'RoleList',
  /**
   * 角色封禁列表
   */
  RoleFreezeList = 'RoleFreezeList',
  /**
   * 角色禁言列表
   */
  RoleGagList = 'RoleGagList',
  /**
   * 角色详情
   */
  RoleInfo = 'RoleInfo',
  /**
   * 角色修改
   */
  RoleUpdate = 'RoleUpdate',
  /**
   * 角色封禁/解封
   */
  RoleFreeze = 'RoleFreeze',
  /**
   * 禁言/解禁角色
   */
  RoleGag = 'RoleGag',

  /**
   * IP封禁列表
   */
  IpFreezeList = 'IpFreezeList',
  /**
   * IP封禁新增
   */
  IpFreezeAdd = 'IpFreezeAdd',
  /**
   * IP封禁修改/解封
   */
  IpFreezeUpdate = 'IpFreezeUpdate',
  /**
   * IP白名单列表
   */
  IpWhitelist = 'IpWhitelist',
  /**
   * IP白名单新增
   */
  IpWhitelistAdd = 'IpWhitelistAdd',
  /**
   * IP白名单修改
   */
  IpWhitelistUpdate = 'IpWhitelistUpdate',
  /**
   * IP白名单移除
   */
  IpWhitelistDelete = 'IpWhitelistDelete',

  /**
   * 游戏帮会列表
   */
  GameFactionList = 'GameFactionList',
  /**
   * 游戏帮会成员列表
   */
  GameFactionMemeberList = 'GameFactionMemeberList',

  /**
   * 维护管理 - 区服列表
   */
  GameServerList = 'GameServerList',
  /**
   * 维护管理 - 区服新增
   */
  GameServerAdd = 'GameServerAdd',
  /**
   * 维护管理 - 区服修改
   */
  GameServerUpdate = 'GameServerUpdate',
  /**
   * 维护管理 - 区服批量修改
   */
  GameServerBatchUpdate = 'GameServerBatchUpdate',
  /**
   * 维护管理 - 区服删除
   */
  GameServerDelete = 'GameServerDelete',
  /**
   * 维护管理 - 区服状态查询
   */
  GameServerStateFind = 'GameServerStateFind',
  /**
   * 维护管理 - 区服状态修改
   */
  GameServerStateUpdate = 'GameServerStateUpdate',
  /**
   * 维护管理 - 区服状态批量修改
   */
  GameServerStateBatchUpdate = 'GameServerStateBatchUpdate',
  /**
   * 维护管理 - 区服状态删除
   */
  GameServerStateDelete = 'GameServerStateDelete',
  /**
   * 维护管理 - 区服开服时间查询
   */
  GameServerOpenTimeFind = 'GameServerOpenTimeFind',
  /**
   * 维护管理 - 区服开服时间修改
   */
  GameServerOpenTimeUpdate = 'GameServerOpenTimeUpdate',
  /**
   * 维护管理 - 区服开关查询
   */
  GameServerSwitchFind = 'GameServerSwitchFind',
  /**
   * 维护管理 - 区服开关修改
   */
  GameServerSwitchUpdate = 'GameServerSwitchUpdate',
  /**
   * 维护管理 - 区服热更新
   */
  GameServerHotUpdate = 'GameServerHotUpdate',

  /**
   * 游戏公告列表
   */
  BroadcastList = 'BroadcastList',
  /**
   * 游戏公告新增
   */
  BroadcastAdd = 'BroadcastAdd',
  /**
   * 游戏公告修改
   */
  BroadcastUpdate = 'BroadcastUpdate',
  /**
   * 游戏公告删除
   */
  BroadcastDelete = 'BroadcastDelete',
  /**
   * GM公告发送
   */
  BroadcastSend = 'BroadcastSend',

  /**
   * GM登录
   */
  GmLogin = 'GmLogin',
  /**
   * GM撤销登录
   */
  GmLogout = 'GmLogout',

  /**
   * 发送邮件
   */
  Mail = 'Mail'
}

/**
 * 游戏接口枚举列表
 */
export const GameApiEnumNames = [
  /* 前端自定义 */
  { name: 'AccountFreezeList', desc: '玩家帐号封禁列表' },
  { name: 'AccountGagList', desc: '玩家帐号禁言列表' },
  { name: 'AccountFreeze', desc: '玩家帐号封禁/解封' },
  { name: 'AccountGag', desc: '玩家帐号禁言/解禁' },
  { name: 'AccountWhitelist', desc: '玩家帐号白名单列表' },
  { name: 'AccountWhitelistAdd', desc: '玩家帐号白名单新增' },
  { name: 'AccountWhitelistUpdate', desc: '玩家帐号白名单修改' },
  { name: 'AccountWhitelistDelete', desc: '玩家帐号白名单移除' },

  { name: 'RoleFreezeList', desc: '玩家角色封禁列表' },
  { name: 'RoleGagList', desc: '玩家角色禁言列表' },
  { name: 'RoleInfo', desc: '玩家角色详情' },
  { name: 'RoleUpdate', desc: '玩家角色修改' },
  { name: 'RoleFreeze', desc: '玩家角色封禁/解封' },
  { name: 'RoleGag', desc: '玩家角色禁言/解禁' },

  { name: 'IpFreezeList', desc: 'IP封禁列表' },
  { name: 'IpFreezeAdd', desc: 'IP封禁新增' },
  { name: 'IpFreezeUpdate', desc: 'IP封禁修改/解封' },
  { name: 'IpWhitelist', desc: 'IP白名单列表' },
  { name: 'IpWhitelistAdd', desc: 'IP白名单新增' },
  { name: 'IpWhitelistUpdate', desc: 'IP白名单修改' },
  { name: 'IpWhitelistDelete', desc: 'IP白名单移除' },

  { name: 'GameFactionList', desc: '游戏帮会列表' },
  { name: 'GameFactionMemeberList', desc: '游戏帮会成员列表' },

  { name: 'GameServerList', desc: '维护管理 - 区服列表' },
  { name: 'GameServerAdd', desc: '维护管理 - 区服新增' },
  { name: 'GameServerUpdate', desc: '维护管理 - 区服修改' },
  { name: 'GameServerBatchUpdate', desc: '维护管理 - 区服批量修改' },
  { name: 'GameServerDelete', desc: '维护管理 - 区服删除' },
  { name: 'GameServerStateFind', desc: '维护管理 - 区服状态查询' },
  { name: 'GameServerStateUpdate', desc: '维护管理 - 区服状态修改' },
  { name: 'GameServerStateBatchUpdate', desc: '维护管理 - 区服状态批量修改' },
  { name: 'GameServerStateDelete', desc: '维护管理 - 区服状态删除' },
  { name: 'GameServerOpenTimeFind', desc: '维护管理 - 区服开服时间查询' },
  { name: 'GameServerOpenTimeUpdate', desc: '维护管理 - 区服开服时间修改' },
  { name: 'GameServerSwitchFind', desc: '维护管理 - 区服开关查询' },
  { name: 'GameServerSwitchUpdate', desc: '维护管理 - 区服开关修改' },
  { name: 'GameServerHotUpdate', desc: '维护管理 - 区服热更新' },

  { name: 'BroadcastList', desc: '游戏公告列表' },
  { name: 'BroadcastAdd', desc: '游戏公告新增' },
  { name: 'BroadcastUpdate', desc: '游戏公告修改' },
  { name: 'BroadcastDelete', desc: '游戏公告删除' },
  { name: 'BroadcastSend', desc: '游戏公告发送' },

  { name: 'GmLogin', desc: 'GM登录' },
  { name: 'GmLogout', desc: 'GM撤销登录' },

  { name: 'Mail', desc: '发送邮件' }
]

/**
 * 字段数据类型键值对
 */
export const GameApiResultSources: { [k: string]: string } = {
  normal: '普通类型',
  exclusive: '关联专属字段'
}

/**
 * 游戏接口管理
 */
const gameApi = {
  /**
   * 获取游戏接口列表
   *
   * @param {(GameApiProps | PagingProps | SorterProps | SearchParamProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: GameApiProps | PagingProps | SorterProps | SearchParamProps) {
    return http.get(`/game/config`, {
      params
    })
  },

  /**
   * 根据游戏ID和平台ID获取接口列表
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @returns
   */
  listByGidPid(gid: Key, pid: Key) {
    return http.get(`/game/config/${gid}/${pid}`)
  },

  /**
   * 获取接口详情
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @param {string} name - 接口名
   * @returns
   */
  get(gid: Key, pid: Key, name: string) {
    return http.get(`/game/config/detail`, {
      params: {
        gid,
        pid,
        name
      }
    })
  },

  /**
   * 获取接口详情（通过接口ID）
   *
   * @param {Key} id - 接口ID
   * @returns
   */
  getById(id: Key) {
    return http.get(`/game/config/detail/${id}`)
  },

  /**
   * 新增游戏接口
   *
   * @param {GameApiProps} data - 表单数据
   * @returns
   */
  add(data: GameApiProps) {
    return http.post(`/game/config`, data)
  },

  /**
   * 修改游戏接口
   *
   * @param {GameApiProps} data - 表单数据
   * @returns
   */
  update(data: GameApiProps) {
    return http.put(`/game/config/${data.id}`, data)
  },

  /**
   * 删除游戏接口
   *
   * @param {number} id - ID
   * @returns
   */
  delete(id: number) {
    return http.delete(`/game/config/${id}`)
  },

  /**
   * 获取服务器定义的接口名枚举
   *
   * @returns
   */
  enums() {
    return http.get('/game/config/enum')
  }
}

export default gameApi
