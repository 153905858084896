import { useContext } from 'react'
import { Layout, message, Tooltip, Avatar, Badge, Button } from 'antd'
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ClearOutlined } from '@ant-design/icons'
import './BasicHeader.less'
import TokenContext from 'src/store/TokenContext'
import UserContext from 'src/store/UserContext'
import { storageClear } from 'src/utilities'
import WorkOrderContext from 'src/store/WorkOrderContext'
import { Link } from 'react-router-dom'
import ShortcutMenu from '../ShortcutMenu'

interface BasicHeaderProps {
  collapsed: boolean
  hasWorkOrder: boolean
  toggle(): void
}

function BasicHeader(props: BasicHeaderProps) {
  const token = useContext(TokenContext)
  const user = useContext(UserContext)
  const workOrder = useContext(WorkOrderContext)

  // 退出
  function signout() {
    token.removeToken()
    message.success('退出成功')

    let redirect = encodeURIComponent(window.location.href)
    window.location.href = `${import.meta.env.VITE_WEB_USER}/signin?redirect=${redirect}`
  }

  // 清除缓存
  function clearCache() {
    storageClear()
  }

  return (
    <Layout.Header className="main-header">
      {props.collapsed ? (
        <MenuUnfoldOutlined className="action collapsed" onClick={props.toggle} />
      ) : (
        <MenuFoldOutlined className="action collapsed" onClick={props.toggle} />
      )}
      <div className="menu">
        <div className="shortcuts">
          {props.hasWorkOrder ? (
            <Link to="/workorder/list">
              <Badge count={workOrder.count} overflowCount={99}>
                <Button type="link">工单</Button>
              </Badge>
            </Link>
          ) : null}
          <ShortcutMenu />
        </div>
        <div className="userinfo">
          <Avatar src={user.info.avatar} />
          <span>{user.info.nick || user.info.uname}</span>
        </div>
        <div className="actions">
          <Tooltip title="清除缓存">
            <ClearOutlined className="action" onClick={clearCache} />
          </Tooltip>
          <Tooltip title="退出">
            <LogoutOutlined className="action signout" onClick={signout} />
          </Tooltip>
        </div>
      </div>
    </Layout.Header>
  )
}

export default BasicHeader
