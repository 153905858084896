import http from 'src/utilities/http'
import { SorterProps, PagingProps } from '..'

/**
 * GM用户权限列表
 *
 * @export
 * @interface PermitUserProps
 */
export interface PermitUserProps {
  id?: number //配置ID
  uid?: number //用户ID
  uname?: string //用户名
  nick?: string //用户昵称
  postfix?:string //用户标签(外部,内部，哪个公司等)
  desc?: string //角色说明
  gid?: number //游戏ID(-1:通配)
  pid?: number //平台ID(-1:通配)
  lcontain?: string //包含服务器lable(JSON数组[a,b])
  lfilter?: string //过滤服务器lable(JSON数组[a,b])
  pids?: string //权限ID(JSON数组[a,b])
  rids?: string //角色ID(JSON数组[a,b])
}

/**
 * 权限管理
 */
const permitUser = {
  /**
   * 获取拥有权限的用户列表
   * @param {(PermitUserProps | SorterProps | PagingProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: PermitUserProps | SorterProps | PagingProps) {
    return http.get('/permits/users', {
      params
    })
  },

  /**
   * 获取详情
   * @param {number} uid - 用户ID
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID(-1所有)
   * @returns
   */
  find(uid: number, gid: number, pid: number) {
    return http.get('/permits/users/find', {
      params: {
        uid,
        gid,
        pid
      }
    })
  },

  /**
   * 修改
   *
   * @param {PermitRoleProps} data - 表单数据(删除:pids=[],=rids[])
   * @returns
   */
  update(data: PermitUserProps) {
    return http.post('/permits/users', data)
  }
}

export default permitUser
