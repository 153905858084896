import http from 'src/utilities/http'
import { SorterProps, PagingProps, getOrigin, PlatType } from 'src/api'
import { UserProps } from './self'

/**
 * 获取当前接口URL
 */
function baseURL() {
  return getOrigin(PlatType.Auth)
}

const users = {
  /**
   * 获取用户列表
   *
   * @param {(UserProps | SorterProps | PagingProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: UserProps | SorterProps | PagingProps) {
    return http.get('/users', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取用户详情
   *
   * @param {number} id - 用户ID
   * @returns
   */
  get(id: number) {
    return http.get(`/users/${id}`, {
      baseURL: baseURL()
    })
  }
}

export default users
