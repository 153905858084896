import http from 'src/utilities/http'
import { DictProps, getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 */
function baseURL() {
  return getOrigin(PlatType.Auth)
}

/**
 * 用户表属性
 *
 * @export
 * @interface UserProps
 */
export interface UserProps {
  id?: number
  uname?: string
  avatar?: string
  email?: string
  phone?: string
  pwd?: string
  nick?: string
  state?: number
  postfix?:string //用户标签,显示时可以追加在用户昵称前或昵称后
  from_come?: string
  from_ip?: string
  from_time?: number
}

/**
 * 用户状态集合
 *
 * @export
 */
export const userStates: DictProps[] = [
  { id: 1, name: '正常' },
  { id: -1, name: '暂停' },
  { id: -2, name: '冻结' }
]

/**
 * 用户自己
 */
const self = {
  /**
   * 获取用户信息
   *
   */
  info() {
    return http.get('/user/info', {
      baseURL: baseURL()
    })
  },

  /**
   * 获取用户在Auth的权限
   *
   */
  authPermits() {
    return http.get('/user/permits', {
      baseURL: baseURL()
    })
  },

  /**
   * 获取用户在Kernel的权限
   *
   */
  kernelPermits() {
    return http.get('/permits/users/self', {
      baseURL: getOrigin(PlatType.Kernel)
    })
  },

  /**
   * 获取用户在GM的权限
   *
   * @param {number} gid - 游戏ID
   * @returns
   */
  permits(gid?: number) {
    return http.get('/permits/users/self', {
      params: {
        gid
      }
    })
  },

  /**
   * 刷新Token
   *
   * @param {string} token - 用于刷新的Token
   * @returns
   */
  refreshToken(token: string) {
    let data = { token }
    return http.post('/auth/refresh', data, {
      baseURL: baseURL()
    })
  }
}

export default self
