import http from 'src/utilities/http'
import { Key } from 'react'

export interface PlayerRoleProps {
  uid?: string
  uname?: string
  role_id?: string
  role_name?: string
  ban?: string
  ban_time?: number
  ban_speech?: string
  ban_speech_time?: number
}

/**
 * 玩家角色管理
 */
const role = {
  /**
   * 获取玩家角色列表
   *
   * @param {*} [params] - 搜索条件
   */
  list(params?: any) {
    return http.get('/player/role', {
      params
    })
  },

  /**
   * 获取角色详情
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} sid - 区服ID
   * @param {Key} rid - 角色ID
   * @returns
   */
  get(gid: Key, sid: Key, rid: Key) {
    return http.get(`/player/role/${rid}`, {
      params: {
        gid: gid,
        sid: sid
      }
    })
  },

  /**
   * 修改玩家角色信息
   *
   * @param {PlayerProps} data - 表单数据
   * @returns
   */
  update(data: PlayerRoleProps) {
    data = JSON.parse(JSON.stringify(data))
    let uid = data.uid
    delete data.uid
    return http.put(`/player/role/${uid}`, data)
  }
}

export default role
