import { useState, useEffect, useCallback } from 'react'
import { Layout, Menu } from 'antd'
import BasicFooter from './BasicFooter'
import { Link, useLocation } from 'react-router-dom'
import routes, { RouterProps } from 'src/router'
import globalRoutes from 'src/router/global'
import CustomIcon from 'src/components/CustomIcon'
import logo from 'src/assets/images/logo_white.png'
import './BasicSider.less'
import { hasPermit } from 'src/store/PermitContext'
import { filterRoutes } from '.'

const { SubMenu } = Menu

/**
 * 获取单个导航菜单组件
 *
 * @param {RouteProps} item 路由配置
 * @returns 导航菜单组件
 */
function getMenuItem(item: RouterProps) {
  let title = (
    <span>
      <CustomIcon type={item.icon} />
      <span>{item.text}</span>
    </span>
  )
  if (item.routes && item.routes.length !== 0) {
    return (
      <SubMenu key={item.path} title={title}>
        {item.routes.map((el) => getMenuItem(el))}
      </SubMenu>
    )
  } else {
    return (
      <Menu.Item key={item.path} title={item.text}>
        <Link to={item.path}>{title}</Link>
      </Menu.Item>
    )
  }
}

/**
 * 是否显示或者拥有权限
 *
 * @param {RouterProps} el - 路由项
 * @returns
 */
function hasPms(el: RouterProps) {
  if (el.hide !== true && (!el.pms || hasPermit(el.pms))) {
    return true
  }
  return false
}

interface BasicSiderProps {
  collapsed: boolean
}

/**
 * 侧边栏
 *
 * @returns
 */
function BasicSider(props: BasicSiderProps) {
  const [selecteds, setSelecteds] = useState<string[]>([])
  const [openeds, setOpeneds] = useState<string[]>([])
  const [menus, setMenus] = useState<JSX.Element[]>([])
  const [globalMenus, setGlobalMenus] = useState<JSX.Element[]>([])
  const location = useLocation()

  // 过滤菜单
  function filterMenu() {
    let list = filterRoutes(routes, hasPms)
    let menusEl = list.map((item: RouterProps) => getMenuItem(item))
    setMenus(menusEl)

    let globalList = filterRoutes(globalRoutes, hasPms)
    let globalRoutesEl = globalList.map((item: RouterProps) => getMenuItem(item))
    setGlobalMenus(globalRoutesEl)
  }

  useEffect(() => {
    filterMenu()
  }, [])

  // 根据当前路由选中菜单
  const selectedMenu = useCallback(() => {
    let pathname = location.pathname
    let arr = pathname.split('/')
    let list: string[] = []
    if (arr.length) {
      arr.reduce((prev, current, index) => {
        let str = prev + '/' + current
        list.push(str)
        return str
      })
    }
    setOpeneds(list)
    setSelecteds(list)
  }, [location.pathname])

  useEffect(() => {
    selectedMenu()
  }, [selectedMenu])

  // 展开/收缩菜单
  function openMenu(openKeys: any) {
    setOpeneds(openKeys)
  }

  return (
    <Layout.Sider className="main-sider" collapsible trigger={null} collapsed={props.collapsed}>
      <div className="brand">
        <img className="logo" src={logo} alt="logo" />
        <h1 className="title">游戏管理中心</h1>
      </div>
      <Menu theme="dark" mode="inline" openKeys={openeds} selectedKeys={selecteds} onOpenChange={openMenu}>
        <Menu.ItemGroup title="游戏管理">{menus}</Menu.ItemGroup>
        <Menu.ItemGroup title="全局管理">{globalMenus}</Menu.ItemGroup>
      </Menu>
      <BasicFooter />
    </Layout.Sider>
  )
}

export default BasicSider
