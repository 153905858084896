import http from 'src/utilities/http'
import { SorterProps, PagingProps, SearchParamProps } from '..'
import { Key } from 'react'

/**
 * 属性
 */
export interface FaqProps {
  id: number //配置ID
  //
  gid: number // 游戏ID 默认-1（通用）
  pid: number // 平台ID 默认-1（通用）
  //
  order_id: number // 排序ID(ASC:升序排列 ORDER BY `order_id`,`id` ASC)
  //
  pre_tag: string // 前置tag
  tag: string // 当前tag 必须
  title: string // 标题 必须
  content: string // 内容
  //
  pname: string // 平台名
}

/**
 * 管理
 */
const faq = {
  /**
   * 获取列表
   *
   * 需要权限: workorder_faq_list
   *
   * @param {(FaqProps | PagingProps | SorterProps | SearchParamProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: FaqProps | PagingProps | SorterProps | SearchParamProps) {
    return http.get(`/workorder/faq`, {
      params
    })
  },

  /**
   * 获取详情
   *
   * 需要权限: workorder_faq_list
   *
   * @param {Key} id - 配置
   * @returns
   */
  get(id: Key) {
    return http.get(`/workorder/faq/${id}`)
  },

  /**
   * 新增
   *
   * 需要权限: workorder_faq_add
   *
   * @param {FaqProps} data - 表单数据
   * @returns
   */
  add(data: FaqProps) {
    return http.post('/workorder/faq', data)
  },

  /**
   * 修改
   *
   * 需要权限: workorder_faq_update
   *
   * @param {FaqProps} data - 表单数据
   * @returns
   */
  update(data: FaqProps) {
    return http.put(`/workorder/faq/${data.id}`, data)
  },

  /**
   * 删除
   *     可根据配置id删除一条，或根据gid,pid删除多条
   *
   * 需要权限: workorder_faq_del
   *
   * @param {number} id - 配置ID
   * --------
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  delete(id: number, gid: number, pid: number) {
    return http.delete('/workorder/faq', {
      params: {
        id,
        gid,
        pid
      }
    })
  },

  /**
   * 打包发布
   *
   * 需要权限: workorder_faq_pack
   *
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns 发布出去内容
   */
  pack(gid: number, pid: number) {
    return http.post(`/workorder/faq/pack/${gid}/${pid}`)
  }
}

export default faq
