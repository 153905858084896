import http from 'src/utilities/http'
import { PagingProps, SearchParamProps, SorterProps } from '..'
import { Key } from 'react'

/**
 * 区服关联标签属性
 */
export interface ServerRelationProps {
  id: number // ID
  gid: number // 游戏ID
  pid: number // 平台ID
  sid: string // 区服ID
  sname?: string // 区服名
  label: string // 区服标签
  domain?: string //区服请求地址(预留)
  master?: number //合服主服sid(0:未合服)
  union?: number //联服ID(本合服中分组ID)
  login_domain?: string // 登录域名
  extend?:string  //扩展配置(JSONObject{static:true/false})

  // --
  pname: string // 平台名
}

/**
 * 区服关联标签
 */
const serverRelation = {
  /**
   * 获取区服关联标签列表
   *
   * @param {(ServerRelationProps | PagingProps | SearchParamProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: ServerRelationProps | PagingProps | SearchParamProps) {
    return http.get(`/game/server/list`, {
      params
    })
  },
  /**
   * 获取区服关联标签列表
   *
   * @param {(ServerRelationProps | PagingProps | SearchParamProps | SorterProps)} [params] - 搜索条件
   * @returns
   */
  page(params?: ServerRelationProps | PagingProps | SearchParamProps | SorterProps) {
    return http.get(`/game/server`, {
      params
    })
  },
  /**
   * 新增区服关联标签
   *
   * @param {ServerRelationProps} data - 表单数据
   * @returns
   */
  add(data: ServerRelationProps) {
    return http.post('/game/server', data)
  },

  /**
   * 修改区服关联标签
   *
   * @param {ServerRelationProps} data - 表单数据
   * @returns
   */
  update(data: ServerRelationProps) {
    return http.put(`/game/server/${data.id}`, data)
  },

  /**
   * 删除区服关联标签
   *
   * @param {number} id - ID
   * @returns
   */
  delete(id: number) {
    return http.delete(`/game/server/${id}`)
  },

  /**
   * 批量修改
   * @param gid   游戏ID
   * @param pid   平台ID
   * @param sid   游戏ID列表
   * @param data  修改数据,有改,无忽略{label\url\master\union}
   */
  batch(gid: Key, pid: Key, sid: Key[], data: any) {
    return http.post(`/game/server/batch/${gid}/${pid}`, {
      sid: sid,
      data: data
    })
  }
}

export default serverRelation
