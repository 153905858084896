import http from 'src/utilities/http'
import { SorterProps, PagingProps } from '..'

/**
 * GM角色权限列表
 *
 * @export
 * @interface PermitRoleProps
 */
export interface PermitRoleProps {
  id: number //角色ID
  name?: string //角色名称
  desc?: string //角色说明
  lcontain?: string //包含服务器label(JSON数组[a,b])
  lfilter?: string //过滤服务器label(JSON数组[a,b])
  pids?: string //权限ID(JSON数组[])
}

/**
 * 角色权限管理
 */
const permitRole = {
  /**
   * 获取列表
   * @param {(PermitRoleProps | SorterProps | PagingProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: SorterProps | PagingProps) {
    return http.get('/permits/roles', {
      params
    })
  },

  /**
   * 获取详情
   *
   * @param {number} id - 角色ID
   * @returns
   */
  get(id: number) {
    return http.get(`/permits/roles/${id}`)
  },

  /**
   * 新增
   *
   * @param {PermitRoleProps} data - 表单数据
   * @returns
   */
  add(data: PermitRoleProps) {
    return http.post('/permits/roles', data)
  },

  /**
   * 修改
   *
   * @param {PermitRoleProps} data - 表单数据
   * @returns
   */
  update(data: PermitRoleProps) {
    return http.put(`/permits/roles/${data.id}`, data)
  },
  /**
   * 删除
   *
   * @param {number} id - 角色ID
   * @returns
   */
  delete(id: number) {
    return http.delete(`/permits/roles/${id}`)
  }
}

export default permitRole
