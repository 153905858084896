import http from 'src/utilities/http'
import { ECloudCfgType } from './keep'

/**
 * 获取访问域名
 */
const domainApi = {
  /**
   * 获取访问域名
   *
   * @param {ECloudCfgType} type - 存储类型
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  get(type: ECloudCfgType, gid: number, pid: number) {
    return http.get(`/workorder/domain/${type}/${gid}/${pid}`)
  }
}

export default domainApi
