import http from 'src/utilities/http'

/**
 * GM权限列表
 *
 * @export
 * @interface PermitProps
 */
export interface PermitProps {
  id: number //权限ID
  name: string //权限名
  desc?: string //权限描述
}

/**
 * 权限管理
 */
const permit = {
  /**
   * 获取权限列表
   *
   * @returns
   */
  list() {
    return http.get('/permits/permit')
  },

  /**
   * 获取权限详情
   *
   * @param {number} id - 权限ID
   * @returns
   */
  get(id: number) {
    return http.get(`/permits/permit/${id}`)
  },

  /**
   * 新增权限
   *
   * @param {PermitProps} data - 表单数据
   * @returns
   */
  add(data: PermitProps) {
    return http.post('/permits/permit', data)
  },

  /**
   * 修改权限
   *
   * @param {PermitProps} data - 表单数据
   * @returns
   */
  update(data: PermitProps) {
    return http.put(`/permits/permit/${data.id}`, data)
  }
}

export default permit
