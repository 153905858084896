import http from 'src/utilities/http'
import { SorterProps, getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * 游戏类型
 *
 * @export
 * @interface GameTypeProps
 */
export interface GameTypeProps {
  type: string //类型标识
  name?: string //类型名称
  order?: number //排序ID(获取列表时排序)
}

/**
 * 游戏类型管理
 */
const gameType = {
  /**
   * 获取列表
   * @param {(SorterProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: SorterProps) {
    return http.get('/games/types', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取详情
   *
   * @param {string} type - 类型名
   * @returns
   */
  get(type: string) {
    return http.get(`/games/types/${type}`, {
      baseURL: baseURL()
    })
  },

  /**
   * 新增
   *
   * @param {GameTypeProps} data - 表单数据
   * @returns
   */
  add(data: GameTypeProps) {
    return http.post('/games/types', data, {
      baseURL: baseURL()
    })
  },

  /**
   * 修改
   *
   * @param {GameTypeProps} data - 表单数据
   * @returns
   */
  update(data: GameTypeProps) {
    return http.put(`/games/types/${data.type}`, data, {
      baseURL: baseURL()
    })
  },
  /**
   * 删除
   *
   * @param {string} type - 角色ID
   * @returns
   */
  delete(type: string) {
    return http.delete(`/games/types/${type}`, {
      baseURL: baseURL()
    })
  }
}

export default gameType
