import { Suspense, useState, useContext, useEffect, useReducer, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Layout, Alert } from 'antd'
import BasicHeader from './BasicHeader'
import BasicSider from './BasicSider'
import routes, { RouterProps } from 'src/router'
import globalRoutes from 'src/router/global'
import './index.less'
import Loading from '../Loading'
import TokenContext from 'src/store/TokenContext'
import UserContext, { userReducer, userStore } from 'src/store/UserContext'
import WorkOrderContext, { workOrderReducer, workOrderStore } from 'src/store/WorkOrderContext'
import api from 'src/api'
import { hasPermit } from 'src/store/PermitContext'
import { EWorkorderState } from 'src/api/workorder/workorder'

/**
 * 是否拥有权限
 *
 * @param {RouterProps} el - 路由项
 * @returns
 */
function hasPms(el: RouterProps) {
  if (!el.pms || hasPermit(el.pms)) {
    return true
  }
  return false
}

/**
 * 根据条件过滤路由
 *
 * @export
 * @param {RouterProps[]} list - 路由列表
 * @param {(el: RouterProps) => boolean} hasPms - 权限判断方法
 * @returns
 */
export function filterRoutes(list: RouterProps[], hasPms: (el: RouterProps) => boolean) {
  return list.filter((el) => {
    if (hasPms(el)) {
      if (el.routes) {
        el.routes = el.routes.filter((child) => {
          if (child.routes) {
            child.routes = child.routes.filter((subChild) => hasPms(subChild))
          }
          return hasPms(child)
        })
      }
      return true
    }
    return false
  })
}

/**
 * 基础布局
 *
 * @returns
 */
function BasicLayout() {
  const [loading, setLoading] = useState(true)
  const [collapsed, setCollapsed] = useState(false)
  const [hasWorkOrder, setHasWorkOrder] = useState(false)
  const [mainRoutes, setMainRoutes] = useState<JSX.Element[]>([])

  const [user, userDispatch] = useReducer(userReducer, userStore)
  const [workOrder, workOrderDispatch] = useReducer(workOrderReducer, workOrderStore)

  const token = useContext(TokenContext)

  // 获取用户信息
  function fetchInfo() {
    api.self
      .info()
      .then((result) => {
        if (result.code === 1) {
          userDispatch({ type: 'setInfo', payload: result.data })
        }
      })
      .finally(() => setLoading(false))
  }

  // 拉取客服负责所属的带领取工单总数
  function fetchWorkOrderCount() {
    api.workorder.count(EWorkorderState.Build).then((result) => {
      if (result.code === 1) {
        workOrderDispatch({ type: 'setCount', payload: result.data || 0 })
      }
    })
  }

  useEffect(() => {
    let time: any
    api.waiter.self().then((result) => {
      if (result.code === 1 && result.data && result.data.length !== 0) {
        setHasWorkOrder(true)
        fetchWorkOrderCount()
        time = setInterval(() => {
          fetchWorkOrderCount()
        }, 60 * 1000)
      } else {
        setHasWorkOrder(false)
      }
    })
    return () => {
      clearInterval(time)
    }
  }, [])

  useEffect(() => {
    if (!token.token) {
      return
    }

    fetchInfo()
  }, [token.token])

  useEffect(() => {
    let data: JSX.Element[] = []

    // 构建路由
    function mapRoutes(list: RouterProps[]) {
      list.forEach((item) => {
        if (item.redirect) {
          data.push(<Redirect exact={item.exact} key={item.path} from={item.path} to={item.redirect} />)
        } else if (item.component) {
          data.push(<Route exact={item.exact} key={item.path} path={item.path} component={item.component} />)
        }
        if (item.routes) {
          mapRoutes(item.routes)
        }
      })
    }

    let fRoutes = filterRoutes(routes, hasPms)
    let fGlobalRoutes = filterRoutes(globalRoutes, hasPms)

    mapRoutes(fRoutes)
    mapRoutes(fGlobalRoutes)

    setMainRoutes(data)
  }, [])

  // 展开/折叠
  function toggleCollapsed() {
    setCollapsed(!collapsed)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <UserContext.Provider value={user}>
          <WorkOrderContext.Provider value={workOrder}>
            <Layout>
              <BasicSider collapsed={collapsed} />
              <Layout className="main-layout">
                <BasicHeader collapsed={collapsed} hasWorkOrder={hasWorkOrder} toggle={toggleCollapsed} />
                <Layout.Content className="main-content">
                  <Suspense fallback={<Loading />}>
                    <Alert.ErrorBoundary>
                      <Switch>
                        {mainRoutes}
                        <Route path="*" component={lazy(() => import('src/components/The404'))} />
                      </Switch>
                    </Alert.ErrorBoundary>
                  </Suspense>
                </Layout.Content>
              </Layout>
            </Layout>
          </WorkOrderContext.Provider>
        </UserContext.Provider>
      )}
    </>
  )
}

export default BasicLayout
