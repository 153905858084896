import http from 'src/utilities/http'
import { Key } from 'react'

/**
 * 游戏道具属性
 */
export interface GameItemModel {
  ItemID: number // 道具ID
  ItemType: number // 道具类型
  ItemName: string // 道具名称
}

/**
 * 游戏道具
 */
const gameItem = {
  /**
   * 获取游戏道具列表
   *
   * @param {Key} gid 游戏ID
   * @param {Key} pid 平台ID
   * @param {Key} sid 区服ID
   * @returns
   */
  list(gid: Key, pid: Key, sid: Key) {
    return http.get(`/game/item/${gid}/${pid}/${sid}`)
  }
}

export default gameItem
