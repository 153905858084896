import http from 'src/utilities/http'
import { SorterProps, getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * 区服标签
 *
 * @export
 * @interface ServerLabelProps
 */
export interface ServerLabelProps {
  label: string //标签标识(不可见,主键,不允许:All[全部])
  name: string //标签名称(可见)
}

/**
 * 区服标签管理
 */
const serverLabel = {
  /**
   * 获取列表
   * @param {( SorterProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: SorterProps) {
    return http.get('/games/servers/labels', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取详情
   *
   * @param {string} label - 标签标识
   * @returns
   */
  get(label: string) {
    return http.get(`/games/servers/labels/${label}`, {
      baseURL: baseURL()
    })
  },

  /**
   * 新增
   *
   * @param {ServerLabelProps} data - 表单数据
   * @returns
   */
  add(data: ServerLabelProps) {
    return http.post('/games/servers/labels', data, {
      baseURL: baseURL()
    })
  },

  /**
   * 修改
   *
   * @param {ServerLabelProps} data - 表单数据
   * @returns
   */
  update(data: ServerLabelProps) {
    return http.put(`/games/servers/labels/${data.label}`, data, {
      baseURL: baseURL()
    })
  },
  /**
   * 删除
   *
   * @param {string} name - 标签标识
   * @returns
   */
  delete(label: string) {
    return http.delete(`/games/servers/labels/${label}`, {
      baseURL: baseURL()
    })
  }
}

export default serverLabel
