import http from 'src/utilities/http'
import { SorterProps, PagingProps, getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 *
 * @returns
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * Kernel角色权限列表
 *
 * @export
 * @interface KernelPermitRoleProps
 */
export interface KernelPermitRoleProps {
  id: number //角色ID
  name?: string //角色名称
  desc?: string //角色说明
  pids?: string //权限ID(JSON数组[])
}

/**
 * Kernel角色权限管理
 */
const kernelPermitRole = {
  /**
   * 获取列表
   * @param {(KernelPermitRoleProps | SorterProps | PagingProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: SorterProps | PagingProps) {
    return http.get('/permits/roles', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取详情
   *
   * @param {number} id - 角色ID
   * @returns
   */
  get(id: number) {
    return http.get(`/permits/roles/${id}`, {
      baseURL: baseURL()
    })
  },

  /**
   * 新增
   *
   * @param {KernelPermitRoleProps} data - 表单数据
   * @returns
   */
  add(data: KernelPermitRoleProps) {
    return http.post('/permits/roles', data, {
      baseURL: baseURL()
    })
  },

  /**
   * 修改
   *
   * @param {KernelPermitRoleProps} data - 表单数据
   * @returns
   */
  update(data: KernelPermitRoleProps) {
    return http.put(`/permits/roles/${data.id}`, data, {
      baseURL: baseURL()
    })
  },
  /**
   * 删除
   *
   * @param {number} id - 角色ID
   * @returns
   */
  delete(id: number) {
    return http.delete(`/permits/roles/${id}`, {
      baseURL: baseURL()
    })
  }
}

export default kernelPermitRole
