import http from 'src/utilities/http'
import { SorterProps, PagingProps, getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 *
 * @returns
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * Kernel用户权限列表
 *
 * @export
 * @interface KernelPermitUserProps
 */
export interface KernelPermitUserProps {
  id?: number //配置ID
  uid?: number //用户ID
  uname?: string //用户名
  nick?: string //用户昵称
  desc?: string //角色说明
  pids?: string //权限ID(JSON数组[a,b])
  rids?: string //角色ID(JSON数组[a,b])
}

/**
 * 权限管理
 */
const kernelPermitUser = {
  /**
   * 获取拥有权限的用户列表
   * @param {(KernelPermitUserProps | SorterProps | PagingProps)} [params] - 搜索条件
   *
   * @returns
   */
  list(params?: KernelPermitUserProps | SorterProps | PagingProps) {
    return http.get('/permits/users', {
      baseURL: baseURL(),
      params
    })
  },

  /**
   * 获取详情
   * @param {number} uid - 用户ID
   * @returns
   */
  find(uid: number) {
    return http.get('/permits/users/find', {
      baseURL: baseURL(),
      params: {
        uid,
        gid: -1,
        pid: -1
      }
    })
  },

  /**
   * 修改
   *
   * @param {PermitRoleProps} data - 表单数据(删除:pids=[],=rids[])
   * @returns
   */
  update(data: KernelPermitUserProps) {
    return http.post('/permits/users', data, {
      baseURL: baseURL()
    })
  }
}

export default kernelPermitUser
