import { useState } from 'react'

/**
 * 快捷导航
 *
 * @returns
 */
function ShortcutMenu() {
  const [navs] = useState(() => {
    let list = [
      { name: '用户鉴权中心', url: `${import.meta.env.VITE_WEB_USER}` },
      { name: '游戏管理中心', url: `${import.meta.env.VITE_WEB_GM}` },
      { name: '数据分析中心', url: `${import.meta.env.VITE_WEB_DAC}` },
      { name: '数据管理中心', url: `${import.meta.env.VITE_WEB_DBCFG}` }
    ]
    list = list.filter((item) => item.url !== import.meta.env.VITE_WEB_SELF)
    return list
  })

  return (
    <>
      {navs.map((el) => (
        <a key={el.url} href={el.url} target="_blank" rel="noreferrer noopener">
          {el.name}
        </a>
      ))}
    </>
  )
}

export default ShortcutMenu
