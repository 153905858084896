import http from 'src/utilities/http'

/**
 * 玩家管理
 */
const player = {
  /**
   * 获取玩家列表
   *
   * @param {*} [params] - 搜索条件
   */
  list(params?: any) {
    return http.get('/player', {
      params
    })
  },

  /**
   * 修改玩家信息
   *
   * @param {*} data - 表单数据
   * @returns
   */
  update(data: any) {
    data = JSON.parse(JSON.stringify(data))
    let uid = data.uid
    delete data.uid
    return http.put(`/player/${uid}`, data)
  }
}

export default player
