import http from 'src/utilities/http'
import { SorterProps, PagingProps, SearchParamProps, DictProps } from '..'
import { Key } from 'react'

/**
 * 属性
 */
export interface KeepProps {
  id: number //配置ID
  type: ECloudCfgType //配置类型(CloudCfgType)
  //
  gid: number // 游戏ID 默认-1（通用）
  pid: number // 平台ID 默认-1（通用）
  //
  domain: string //访问域名
  //
  url: string // 发布路径
  keep_env: string // 存储环境配置(CloudKeepEnv)
  desc: string // 说明
  //
  pname: string // 平台名
}
/**
 * 存储配置类型
 *
 * @export
 */
export enum ECloudCfgType {
  /**
   * FAQ发布
   */
  FAQ = 1,
  /**
   * 工单附件
   */
  Attach = 2
}

/**
 * 存储配置类型列表
 */
export const CloudCfgTypes: DictProps[] = [
  { id: 1, name: 'FAQ发布' },
  { id: 2, name: '工单附件' }
]

/**
 * 云存储环境配置
 */
export interface CloudKeepEnv {
  type: ECloudKeepType //存储类型CloudKeepType
  //
  region: string // 存储桶区域/站点
  bucket: string // 存储桶
  assess: string // 鉴权Key
  secret: string // 鉴权密钥
}
/**
 * 云存储类型
 *
 * @export
 */
export enum ECloudKeepType {
  OSS = 1, //阿里云-OSS
  COS = 2, //腾讯云-COS
  S3 = 3 //亚马逊-S3
}

/**
 * 云存储类型列表
 */
export const CloudKeepTypes: DictProps[] = [
  { id: 1, name: '阿里云-OSS' },
  { id: 2, name: '腾讯云-COS' },
  { id: 3, name: '亚马逊-S3' }
]
/**
 * 存储配置
 */
const keepApi = {
  /**
   * 获取列表
   *
   * 需要权限: workorder_keep_list
   *
   * @param {(KeepProps | PagingProps | SorterProps | SearchParamProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: KeepProps | PagingProps | SorterProps | SearchParamProps) {
    return http.get(`/workorder/keep`, {
      params
    })
  },

  /**
   * 获取详情
   *
   * 需要权限: workorder_keep_list
   *
   * @param {Key} id - 配置
   * @returns
   */
  get(id: Key) {
    return http.get(`/workorder/keep/${id}`)
  },

  /**
   * 新增
   *
   * 需要权限: workorder_keep_add
   *
   * @param {KeepProps} data - 表单数据
   * @returns
   */
  add(data: KeepProps) {
    return http.post(`/workorder/keep`, data)
  },

  /**
   * 修改
   *
   * 需要权限: workorder_keep_update
   *
   * @param {KeepProps} data - 表单数据
   * @returns
   */
  update(data: KeepProps) {
    return http.put(`/workorder/keep/${data.id}`, data)
  },

  /**
   * 删除
   *     可根据配置id删除一条，或根据gid,pid删除多条
   *
   * 需要权限: workorder_keep_del
   *
   * @param {number} id - 配置ID
   * --------
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  delete(id: number, gid: number, pid: number) {
    return http.delete(`/workorder/keep`, {
      params: {
        id,
        gid,
        pid
      }
    })
  }
}

export default keepApi
