import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons'
import './BasicFooter.less'
import moment from 'moment'

function Footer() {
  return (
    <Layout.Footer className="main-footer">
      Copyright <CopyrightOutlined /> {moment().format('YYYY')} Mokylin
    </Layout.Footer>
  )
}

export default Footer
