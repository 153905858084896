import http from 'src/utilities/http'
import { Key } from 'react'
import { EnumGameApiName } from './game_api'

export interface GameDataProps {
  id: number // ID
  workflow: EnumGameDataWorkflow // 申请工作流
  type: EnumBroadcastType | number // 申请类型
  api_name: EnumGameApiName // 接口名

  gid: number // 游戏ID
  pid: number // 平台ID
  pname: number // 平台名
  sids: string // 区服ID集合，JSON格式：[1,2,3]

  c_id: number // 申请人ID
  c_name: string // 申请人名称
  c_time: number // 申请时间
  u_id: number // 审批人ID
  u_name: string // 审批人名称
  u_time: number // 审批时间

  state: EnumGameDataState // 流程状态
  send_state: EnumGameDataSendState // 发送状态
  reason: string // 申请原因
  refuse: string // 拒绝原因
  result: any // 执行结果，JSON格式：{time:0,sid:{1:1,2:-2147483648}}；time：时间戳，单位秒； sid：1表示成功、-2147483648表示服务器不通、其他值表示失败

  data: any // 表单信息，传输给游戏用，JSON格式

  // GM公告 时间参数
  datetime: string // 时间段 [2012][9][w4,w5,w6][00:00-23:59] 表示2012年9月的周五、周六、周日
  interval: number // 间隔时间，单位：秒

  // 邮件 道具信息
  items: any // 道具、礼包，JSON格式
}

/**
 * 申请工作流
 *
 * @export
 * @enum {number}
 */
export enum EnumGameDataWorkflow {
  /**
   * 公告
   */
  Broadcast = 1,
  /**
   * 邮件
   */
  Mail = 2,
  /**
   * 角色属性
   */
  Role = 3,
  /**
   * 帮会属性
   */
  Guild = 4
}

/**
 * 公告类型
 */
export const BroadcastTypes = [
  { id: 1, name: '游戏公告' },
  { id: 2, name: 'GM公告' }
]

/**
 * 公告类型枚举
 *
 * @export
 * @enum {number}
 */
export enum EnumBroadcastType {
  /**
   * 游戏公告
   */
  Game = 1,
  /**
   * GM公告
   */
  Gm = 2
}

/**
 * 流程状态列表
 */
export const GameDataStates = [
  { id: 1, name: '暂存' },
  { id: 2, name: '待审批' },
  { id: 3, name: '已通过' },
  { id: 4, name: '已拒绝' }
]

/**
 * 流程状态枚举
 *
 * @export
 * @enum {number}
 */
export enum EnumGameDataState {
  /**
   * 暂存
   */
  Temporary = 1,
  /**
   * 待审批
   */
  Approval = 2,
  /**
   * 已通过
   */
  Pass = 3,
  /**
   * 已拒绝
   */
  Refuse = 4
}

/**
 * 发送状态列表
 */
export const GameDataSendStates = [
  { id: 1, name: '未发送' },
  { id: 2, name: '发送成功' },
  { id: 3, name: '发送失败' },
  { id: 4, name: '部分成功' },
  { id: 5, name: '已取消' },
  { id: 6, name: '已到期' }
]

/**
 * 发送状态枚举
 *
 * @export
 * @enum {number}
 */
export enum EnumGameDataSendState {
  /**
   * 未发送
   */
  Unsent = 1,
  /**
   * 发送成功
   */
  Success = 2,
  /**
   * 发送失败
   */
  Fail = 3,
  /**
   * 部分成功
   */
  Partial = 4,
  /**
   * 已取消
   */
  Cancel = 5,
  /**
   * 已到期
   */
  Expired = 6
}

/**
 * 游戏数据管理
 */
const gameData = {
  /**
   * 获取列表（根据c_id判断是申请列表还是审批列表）
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {*} [params]
   */
  list(workflow: EnumGameDataWorkflow, params?: any) {
    return http.get(`/game/data/${workflow}`, {
      params
    })
  },

  /**
   * 获取详情
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {Key} id - ID
   */
  get(workflow: EnumGameDataWorkflow, id: Key) {
    return http.get(`/game/data/${workflow}/detail/${id}`)
  },

  /**
   * 新增申请
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {GameDataProps} data - 表单
   * @returns
   */
  add(workflow: EnumGameDataWorkflow, data: GameDataProps) {
    return http.post(`/game/data/${workflow}`, data)
  },

  /**
   * 修改申请
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {GameDataProps} data - 表单
   */
  update(workflow: EnumGameDataWorkflow, data: GameDataProps) {
    return http.put(`/game/data/${workflow}/${data.id}`, data)
  },

  /**
   * 删除申请
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {Key} id - ID
   * @returns
   */
  delete(workflow: EnumGameDataWorkflow, id: Key) {
    return http.delete(`/game/data/${workflow}/${id}`)
  },

  /**
   * 撤销
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {Key} id - ID
   * @returns
   */
  revoke(workflow: EnumGameDataWorkflow, id: Key) {
    return http.put(`/game/data/${workflow}/revoke/${id}`)
  },

  /**
   * 审批
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {Key} id - ID
   * @param {EnumGameDataState} state - 状态 3：通过、 4：拒绝
   * @param {string} [refuse] - 拒绝原因
   * @returns
   */
  approval(workflow: EnumGameDataWorkflow, id: Key, state: EnumGameDataState, refuse?: string) {
    return http.post(`/game/data/${workflow}/approval/${id}`, {
      state,
      refuse
    })
  },

  /**
   * 终止发送【GM公告下才有用】
   *
   * @param {EnumGameDataWorkflow} workflow - 申请工作流
   * @param {Key} id
   * @returns
   */
  termination(workflow: EnumGameDataWorkflow, id: Key) {
    return http.put(`/game/data/${workflow}/cancel/${id}`)
  }
}

export default gameData
