import http from 'src/utilities/http'
import { PagingProps, SearchParamProps } from '..'

/**
 * 游戏日志实体
 *
 * @export
 * @interface GameLogEntity
 */
export interface GameLogEntity {
  //---日志查询条件
  gid: number // 游戏ID
  pid?: number // 平台ID
  sid?: number // 区服ID
  uid?: string // 用户ID
  uname?: string // 用户名
  role_id?: string // 角色ID
  event?: number // 事件ID
  table: string // 事件表名
  start_time: string // 开始时间
  end_time: string // 结束时间
  //-----
  db_type?: number // 数据源类型(2:Mysql,5:Presto)
  //-----查询日志字段
  querist?: string // 请求者ID
  task?: string // 查询任务ID,根据此ID查询任务结果
  task_start?: number // 查询任务创建时间抽(秒)
  db_begin?: number // 数据库开始查询时间抽(秒)[null或者<=0 还在排队中]
  db_end?: number // 数据库结束查询时间抽(秒)[null或者<=0 正在查询中]
}

/**
 * 日志事件实体
 *
 * @export
 * @interface GameLogEventEntity
 */
export interface GameLogEventEntity {
  id: number
  name: string
}

/**
 * 日志表结构实体
 *
 * @export
 * @interface GameLogStructEntity
 */
export interface GameLogStructEntity {
  name: string
  desc: string
  type: string
}

/**
 * 游戏日志
 */
const gameLog = {
  /**
   * 获取游戏日志事件列表
   *
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  events(gid: number, pid: number) {
    return http.get('/game/log/event', {
      params: { gid, pid }
    })
  },

  /**
   * 获取游戏表名列表
   *
   * @param {number} gid - 游戏ID
   * @param {number} pid - 平台ID
   * @returns
   */
  tables(gid: number, pid: number) {
    return http.get('/game/log/table/list', {
      params: { gid, pid }
    })
  },

  /**
   * 获取日志表结构
   *
   * @param {number} gid - 游戏ID
   * @param {string} table - 表名
   * @returns
   */
  struct(gid: number, pid: number, name: string) {
    return http.get('/game/log/table/struct', {
      params: {
        gid,
        pid,
        name
      }
    })
  },

  /**
   * 创建日志查询任务
   *
   * @param {GameLogEntity} params - 搜索条件
   * @returns
   */
  createTask(params: GameLogEntity | SearchParamProps) {
    return http.post(`/game/log/task`, params)
  },

  /**
   * 获取任务列表
   *
   * @param {(GameLogEntity | PagingProps)} params - 搜索条件
   * @returns
   */
  detailed(params: GameLogEntity | PagingProps) {
    return http.post(`/game/log/detailed`, params)
  },

  /**
   * 获取日志内容
   *
   * @param {(GameLogEntity | PagingProps)} params - 搜索条件
   * @returns
   */
  content(params: GameLogEntity | PagingProps) {
    return http.post('/game/log/content', params)
  }
}

export default gameLog
