import http, { ResultData } from 'src/utilities/http'
import api, { SorterProps, PagingProps } from '..'
import axios from 'axios'
import { EnvFieldSysProps } from './env_field_sys'

export interface EnvFieldGameProps {
  id: number
  gid: number // 游戏ID
  field: string // 字段名
  name: string // 中文名
}

/**
 * 游戏通用字段映射管理
 */
const envFieldGame = {
  mapping(gid: number) {
    return new Promise<ResultData>((resolve, reject) => {
      axios
        .all([api.envFieldSys.list(), this.list(gid)])
        .then(
          axios.spread((sysResult, gameResult) => {
            if (sysResult.code === 1 && gameResult.code === 1) {
              const sysList: EnvFieldSysProps[] = sysResult.data
              const gameList: EnvFieldGameProps[] = gameResult.data
              const list = sysList.map((el) => {
                const mapping = gameList.find((item) => item.id === el.id)
                return {
                  id: el.id,
                  sys_field: el.field,
                  sys_name: el.name,
                  sys_desc: el.desc,
                  gid: gid,
                  field: mapping?.field || '',
                  name: mapping?.name || ''
                }
              })
              resolve({
                code: 1,
                data: list
              })
            } else {
              resolve({
                code: 0,
                data: []
              })
            }
          })
        )
        .catch((reason) => reject(reason))
    })
  },

  /**
   * 获取游戏字段映射列表
   */
  list(gid: number) {
    return http.get(`/envs/fields/game/${gid}`)
  },

  /**
   * 获取游戏字段映射列表
   */
  page(params?: EnvFieldGameProps | SorterProps | PagingProps) {
    return http.get('/envs/fields/game', {
      params
    })
  },

  /**
   * 获取
   *
   * @param {number} id - 系统配置ID
   * @returns
   */
  get(id: number, gid: number) {
    return http.post(`/envs/fields/game/${id}/${gid}`)
  },

  /**
   * 修改游戏字段映射
   *
   * @param {EnvFieldGameProps} data - 表单数据
   * @returns
   */
  update(data: EnvFieldGameProps) {
    return http.post(`/envs/fields/game/${data.id}/${data.gid}`, data)
  }
}

export default envFieldGame
