import http from 'src/utilities/http'
import { SorterProps, PagingProps, SearchParamProps, DictProps } from '..'
import { Key } from 'react'

/**
 * 属性
 */
export interface WorkorderProps {
  id: number //自增ID
  type: EWorkorderType //类型(咨询/建议/缺陷)(EWorkorderType)
  label: EWorkorderLabel //标签(空,挂起,重复)(WorkorderLabel)
  state: EWorkorderState //状态
  priority: EWorkorderPriority //优先级
  title: string //标题(自动根据FAQ归类)
  desc: string // 问题描述
  score: number //结束评分
  //
  gid: number //游戏ID
  pid: number //平台ID
  sid: number //区服ID
  sname: string //区服名
  //创建者(只存储内部)
  build_uid: number //创建者客服UID
  build_uname: string //创建者客服用户名
  //
  user: string //用户名/ID
  nick: string //[]用户昵称
  role_id: string //[]角色ID
  role_name: string //[]角色名
  phone: string //[]联系电话
  mail: string //[]联系邮箱
  //
  time_build: number //创建时间戳
  time_get: number //服务响应时间
  time_client: number //客户最后操作时间
  time_waiter: number //服务最后操作时间
  //
  handle_user: number //当前服务帐号ID
  handle_nick: string //当前服务帐号昵称
  //JOIN
  pname: string //平台名
  //模糊查找
  like_user: string
  like_role: string
  //工单详情获取
  attach: string[] //工单附件数组
}

/**
 * 工单类型
 *
 * @export
 */
export enum EWorkorderType {
  /**
   * 咨询<默认>
   */
  Consult = 1,
  /**
   * 建议
   */
  Advise = 2,
  /**
   * 缺陷
   */
  Bug = 3
}

/**
 * 工单类型列表
 */
export const WorkorderTypes: DictProps[] = [
  { id: 1, name: '咨询' },
  { id: 2, name: '建议' },
  { id: 3, name: '缺陷' }
]

/**
 * 工单标签
 *
 * @export
 */
export enum EWorkorderLabel {
  /**
   * 标准
   */
  Normal = 1,
  /**
   * 重复
   */
  Repeat = 2,
  /**
   * 挂起(推迟处理)
   */
  Pend = 3
}

/**
 * 工单类型列表
 */
export const WorkorderLabels: DictProps[] = [
  { id: 1, name: '标准' },
  { id: 2, name: '重复' },
  { id: 3, name: '挂起' }
]

/**
 * 工单状态枚举
 *
 * @export
 */
export enum EWorkorderState {
  /**
   * 待领取
   */
  Build = 1,
  /**
   * 处理中
   */
  Mutual = 2,
  /**
   * 待评价
   */
  Shutdown = 3,
  /**
   * 已关闭
   */
  Close = 4
}

/**
 * 工单状态列表
 */
export const WorkorderStates: DictProps[] = [
  { id: 1, name: '待领取' },
  { id: 2, name: '处理中' },
  { id: 3, name: '待评价' },
  { id: 4, name: '已关闭' }
]

/**
 * 优先级
 *
 * @export
 * @enum {number}
 */
export enum EWorkorderPriority {
  /**
   * 一般
   */
  Normal = 1,
  /**
   * 紧急
   */
  Urgent,
  /**
   * 非常紧急
   */
  UrgentVery
}

/**
 * 优先级列表
 */
export const WorkorderPrioritys: DictProps[] = [
  { id: 1, name: '一般' },
  { id: 2, name: '紧急' },
  { id: 3, name: '非常紧急' }
]

/**
 * 工单服务流水类型
 *
 * @export
 */
export enum EFlowType {
  /**
   * 用户
   */
  User = 1,
  /**
   * 客服
   */
  Waiter = 2,
  /**
   * 运营
   */
  Operate = 3,
  /**
   * 开发者
   */
  Develop = 3
}
/**
 * 工单服务流水
 */
export interface FlowProps {
  serial: string // 编号(UUID)
  time: number // 时间戳(秒)
  event: EFlowEvent //流水事件[FlowEvent](正常不传递,转交,等)
  uname: string // 帐号
  nick: string // 昵称
  type: number // 操作对像类型
  desc: string // 文本
  //
  attach: string[] //工单附件数组
}

/**
 * 流水事件
 */
export enum EFlowEvent {
  //获取
  Get = 1,
  //交互
  Mutual = 2,
  //转交
  Transfer = 3,
  //请求关闭
  Shutdown = 4,
  //关闭
  Close = 5
}

/**
 * 工单接口
 */
const workorderApi = {
  /**
   * 获取列表
   *
   * 需要权限: workorder_list
   * @{number} _build 默认0,-1=查询用户创建工单 0=查询全部 1=查询客服创建工单
   *
   * @param {(WorkorderProps | PagingProps | SorterProps | SearchParamProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: WorkorderProps | PagingProps | SorterProps | SearchParamProps) {
    return http.get('/workorder/list', {
      params
    })
  },

  /**
   * 获取工单详情
   *
   * 需要权限: workorder_list
   *
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @returns
   */
  get(gid: number, id: number) {
    return http.get(`/workorder/detail/${gid}/${id}`)
  },

  /**
   * 新增工单
   *
   * 需要权限: workorder_add
   *
   * @param {WorkorderProps} data - 表单数据
   * @returns
   */
  add(data: WorkorderProps) {
    return http.post('/workorder/add', data)
  },

  /**
   * 领取工单
   *
   * 需要权限: workorder_receive
   *
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @returns
   */
  receive(gid: number, id: number) {
    return http.put(`/workorder/receive/${gid}/${id}`)
  },

  /**
   * 转交工单
   *
   * 需要权限: workorder_receive
   *
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {*} data - 客服表单
   * @returns
   */
  transfer(gid: number, id: number, data: any) {
    return http.put(`/workorder/transfer/${gid}/${id}`, data)
  },

  /**
   * 发起关闭
   *
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {string} desc - 关闭描述
   * @returns
   */
  launchClose(gid: number, id: number, desc?: string) {
    return http.put(`/workorder/close/apply/${gid}/${id}`, {
      desc
    })
  },

  /**
   * 评价关闭  (主动关闭 取消关闭)
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {EWorkorderState} state - 关闭类型:Mutual=恢复交互||Close=确认/主动评价关闭
   *
   * @param {number} score - 关闭评价
   * @param {string} desc - 关闭/取消描述
   */
  evaluateClose(gid: number, id: number, state: EWorkorderState, score?: number, desc?: string) {
    return http.put(`/workorder/close/${gid}/${id}`, {
      state,
      score,
      desc
    })
  },

  /**
   * 获取流水详情
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {number} time - 最后更新时间(会获取此时间及后工单流水)
   */
  pullFlow(gid: number, id: number, time: number) {
    return http.get(`/workorder/mutual/${gid}/${id}`, {
      params: {
        time
      }
    })
  },
  /**
   * 添加问题流水
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {number} desc - 回复内容
   */
  addFlow(gid: number, id: number, desc: string) {
    return http.post(`/workorder/mutual/${gid}/${id}`, {
      desc
    })
  },

  /**
   * 添加附件
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   * @param {number} serial - 流水号(0为基础附件)
   */
  addAttach(gid: number, id: number, serial: number, formData: FormData) {
    return http.post(`/workorder/mutual/attach/${gid}/${id}/${serial}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /**
   * 获取已开启工单游戏列表
   */
  openList() {
    return http.get('/workorder/game')
  },
  /**
   * 为游戏开启游戏工单
   */
  build(gid: number) {
    return http.post(`/workorder/game/${gid}`)
  },
  /**
   * 为游戏关闭游戏工单
   */
  close(gid: number) {
    return http.delete(`/workorder/game/${gid}`)
  },
  /**
   * 设置标签
   * @param {number} gid - 游戏ID
   * @param {number} id - 工单ID
   *  @param {EWorkorderLabel} label - 标签(EWorkorderLabel值)
   */
  setLabel(gid: number, id: number, label: EWorkorderLabel) {
    return http.put(`/workorder/label/${gid}/${id}`, {
      label
    })
  },
  /**
   * 获取统计信息
   *
   * @param {Key} countType - 统计类型
   * @param {*} params - 筛选条件
   * @param {number} params.gid - 游戏ID
   * @param {number} [params.pid] - 平台ID
   * @param {number} [params.sid] - 区服ID
   * @param {EWorkorderType} [params.type] - 工单类型
   * @param {EWorkorderState} [params.state] - 工单状态
   * @param {number} [params.start_time] - 工单创建开始时间
   * @param {number} [params.end_time] - 工单创建结束时间
   *
   * @returns
   */
  analysis(countType: Key, params: any) {
    return http.get(`/workorder/count/${countType}`, {
      params
    })
  },

  /**
   * 获取客服负责所属的工单总数
   *
   * @returns
   */
  count(state: EWorkorderState) {
    return http.get('/workorder/total', {
      params: {
        state
      }
    })
  }
}

export default workorderApi
