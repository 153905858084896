import http, { ResultData } from 'src/utilities/http'
import { Key } from 'react'
import { storageGet, storageSet } from 'src/utilities'

export type ServerProps = {
  gid: number // 游戏ID
  gname: string // 游戏名
  pid: number // 平台ID
  pname: string // 平台名
  sid: number // 区服ID
  sname: string // 区服名
  label: string // 区服标签，JSON[]
  domain?: string // 区服接口地址
  master: number // 合服主服sid(0:未合服)
  union: number // 联服ID(本合服中分组ID)
}

/**
 * 游戏区服管理
 */
const server = {
  /**
   * 获取区服列表
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @returns
   */
  list(gid: Key, pid: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      http
        .get(`/game/zone/${gid}/${pid}`)
        .then((result) => {
          if (result.code === 1) {
            let data = result.data || []

            if (data.length !== 0) {
              storageSet(`servers_${gid}_${pid}`, JSON.stringify(data))
            }

            resolve({
              code: 1,
              data: data
            })
          } else {
            resolve({
              code: 0,
              data: null,
              msg: result.msg
            })
          }
        })
        .catch(reject)
    })
  },

  /**
   * 获取区服列表，优先从Storage获取
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @returns
   */
  cacheList(gid: Key, pid: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      let serversJson = storageGet(`servers_${gid}_${pid}`)
      if (serversJson) {
        let servers = JSON.parse(serversJson)
        resolve({
          code: 1,
          data: servers
        })
      } else {
        this.list(gid, pid)
          .then((result) => {
            let data = result.data || []
            resolve({
              code: result.code,
              data: data,
              msg: result.msg
            })
          })
          .catch(reject)
      }
    })
  },

  /**
   * 获取详情
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @param {Key} sid - 区服ID
   * @returns
   */
  getLoginDomain(gid: Key, pid: Key, sid: Key) {
    return http.get(`/game/zone/login/domain/${gid}/${pid}/${sid}`)
  }
}

export default server
