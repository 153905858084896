import { Result, Button } from 'antd'

function The403() {
  // 退出登录
  function signout() {
    let redirect = encodeURIComponent(window.location.href)
    window.location.href = `${import.meta.env.VITE_WEB_USER}/signin?redirect=${redirect}`
  }

  return (
    <Result
      status="403"
      title="403"
      subTitle="哎呀，没有访问权限呢！"
      extra={
        <Button type="primary" onClick={signout}>
          更换登录帐号
        </Button>
      }
    />
  )
}

export default The403
