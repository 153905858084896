import http from 'src/utilities/http'
import { SorterProps, PagingProps } from '..'

/**
 * 日志记录实体
 *
 * @export
 * @interface LogProps
 */
export interface LogProps {
  id: number // ID
  gid: number // 游戏ID
  pid: number // 平台ID
  sid: string // 区服ID
  uid: number // 帐号ID
  uname: string // 帐号名
  cmd: string // 执行API名称
  params: string // 执行参数
  result: string // 执行结果
}

/**
 * 日志记录
 */
const log = {
  /**
   * 获取日志列表
   *
   * @param {(LogProps | SorterProps | PagingProps)} [params] - 搜索条件
   * @returns
   */
  list(params?: LogProps | SorterProps | PagingProps) {
    return http.get('/gmlog', {
      params
    })
  }
}

export default log
