import http from 'src/utilities/http'
import { getOrigin, PlatType } from '..'

/**
 * 获取当前接口URL
 *
 * @returns
 */
function baseURL() {
  return getOrigin(PlatType.Kernel)
}

/**
 * Kernel权限列表
 *
 * @export
 * @interface KernelPermitProps
 */
export interface KernelPermitProps {
  id: number //权限ID
  name: string //权限名
  desc?: string //权限描述
}

/**
 * Kernel权限管理
 */
const kernelPermit = {
  /**
   * 获取权限列表
   *
   * @returns
   */
  list() {
    return http.get('/permits/permit', {
      baseURL: baseURL()
    })
  },

  /**
   * 获取权限详情
   *
   * @param {number} id - 权限ID
   * @returns
   */
  get(id: number) {
    return http.get(`/permits/permit/${id}`, {
      baseURL: baseURL()
    })
  },

  /**
   * 新增权限
   *
   * @param {KernelPermitProps} data - 表单数据
   * @returns
   */
  add(data: KernelPermitProps) {
    return http.post('/permits/permit', data, {
      baseURL: baseURL()
    })
  },

  /**
   * 修改权限
   *
   * @param {KernelPermitProps} data - 表单数据
   * @returns
   */
  update(data: KernelPermitProps) {
    return http.put(`/permits/permit/${data.id}`, data, {
      baseURL: baseURL()
    })
  }
}

export default kernelPermit
