import { lazy } from 'react'

/**
 * 路由属性
 *
 * @export
 * @interface RouterProps
 */
export interface RouterProps {
  path: string // 路径
  text: string // 文字
  icon?: any // 图标
  exact?: boolean // 是否完全匹配
  hide?: boolean // 是否隐藏
  pms?: string // 权限名称
  redirect?: string // 跳转地址
  component?: any // 页面组件
  routes?: RouterProps[] // 子路由
}

const routes: RouterProps[] = [
  {
    path: '/',
    text: '首页',
    icon: 'HomeOutlined',
    exact: true,
    hide: true,
    component: lazy(() => import('src/views/Home'))
  },
  {
    path: '/player',
    text: '玩家管理',
    icon: 'UserOutlined',
    exact: true,
    pms: 'Gm.menu_player_manager',
    routes: [
      {
        path: '/player/account',
        text: '帐号列表',
        exact: true,
        pms: 'Gm.player_account_list',
        component: lazy(() => import('src/views/Player/Account'))
      },
      {
        path: '/player/role',
        text: '角色列表',
        exact: true,
        pms: 'Gm.player_role_list',
        component: lazy(() => import('src/views/Player/Role'))
      },
      {
        path: '/player/role/:gid/:pid/:sid/:key/:value',
        text: '角色详情',
        exact: true,
        hide: true,
        pms: 'Gm.player_role_list',
        component: lazy(() => import('src/views/Player/Role/RoleInfo'))
      },
      {
        path: '/player/freeze',
        text: '封禁列表',
        exact: true,
        pms: 'Gm.player_freeze_list',
        component: lazy(() => import('src/views/Player/Freeze'))
      },
      {
        path: '/player/gag',
        text: '禁言列表',
        exact: true,
        pms: 'Gm.player_gag_list',
        component: lazy(() => import('src/views/Player/Gag'))
      },
      {
        path: '/player/ipfreeze',
        text: 'IP封禁列表',
        exact: true,
        pms: 'Gm.player_ip_freeze_list',
        component: lazy(() => import('src/views/Player/Freeze/IpFreezeList'))
      },
      {
        path: '/player/faction',
        text: '游戏帮会',
        exact: true,
        pms: 'Gm.player_faction_list',
        component: lazy(() => import('src/views/Player/Faction'))
      },
      {
        path: '/player/gamelog',
        text: '游戏日志',
        exact: true,
        pms: 'Gm.game_log_list',
        component: lazy(() => import('src/views/Player/GameLog'))
      }
    ]
  },
  {
    path: '/game',
    text: '游戏管理',
    icon: 'NotificationOutlined',
    exact: true,
    pms: 'Gm.menu_game_manager',
    routes: [
      {
        path: '/game/broadcast/list',
        text: '游戏公告',
        exact: true,
        pms: 'Gm.game_broadcast_apply',
        component: lazy(() => import('src/views/Game/Broadcast/List'))
      },
      {
        path: '/game/broadcast/apply',
        text: '公告申请',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Game/Broadcast/Apply'))
      },
      {
        path: '/game/broadcast/approval',
        text: '公告审批',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Game/Broadcast/Approval'))
      },
      {
        path: '/game/mail/apply',
        text: '邮件申请',
        exact: true,
        pms: 'Gm.game_mail_apply',
        component: lazy(() => import('src/views/Game/Mail/Apply'))
      },
      {
        path: '/game/mail/apply/add',
        text: '新增邮件申请',
        exact: true,
        hide: true,
        pms: 'Gm.game_mail_apply',
        component: lazy(() => import('src/views/Game/Mail/Apply/MailApply'))
      },
      {
        path: '/game/mail/apply/edit/:id',
        text: '修改邮件申请',
        exact: true,
        hide: true,
        pms: 'Gm.game_mail_apply',
        component: lazy(() => import('src/views/Game/Mail/Apply/MailApply'))
      },
      {
        path: '/game/mail/approval',
        text: '邮件审批',
        exact: true,
        pms: 'Gm.game_mail_approval',
        component: lazy(() => import('src/views/Game/Mail/Approval'))
      }
    ]
  },
  {
    path: '/maintain',
    text: '维护管理',
    icon: 'ToolOutlined',
    exact: true,
    pms: 'Gm.menu_maintain_manager',
    routes: [
      {
        path: '/maintain/server',
        text: '区服列表',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Maintain/Server'))
      },
      {
        path: '/maintain/servermerge',
        text: '合服区服',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Maintain/ServerMerge'))
      },
      {
        path: '/maintain/whitelist',
        text: '帐号白名单',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Maintain/Whitelist'))
      },
      {
        path: '/maintain/ipwhitelist',
        text: 'IP白名单',
        exact: true,
        pms: '',
        component: lazy(() => import('src/views/Maintain/Whitelist/IpWhitelist'))
      }
    ]
  },
  {
    path: '/workorder',
    text: '工单系统',
    icon: 'BugOutlined',
    exact: true,
    pms: 'Gm.menu_workorder_manager',
    routes: [
      {
        path: '/workorder/list',
        text: '工单列表',
        exact: true,
        component: lazy(() => import('src/views/WorkOrder/List')),
        routes: [
          {
            path: '/workorder/list/add',
            text: '提交工单',
            hide: true,
            exact: true,
            component: lazy(() => import('src/views/WorkOrder/List/WorkOrderAdd'))
          }
        ]
      },
      {
        path: '/workorder/analysis',
        text: '工单统计',
        exact: true,
        component: lazy(() => import('src/views/WorkOrder/Analysis'))
      },
      {
        path: '/workorder/faq',
        text: '常见问题',
        exact: true,
        pms: 'Gm.workorder_faq_list',
        component: lazy(() => import('src/views/WorkOrder/Faq'))
      },
      {
        path: '/workorder/waiter',
        text: '客服列表',
        exact: true,
        pms: 'Gm.workorder_waiter_list',
        component: lazy(() => import('src/views/WorkOrder/Waiter'))
      },
      {
        path: '/workorder/keep',
        text: '工单存储',
        exact: true,
        pms: 'Gm.workorder_keep_list',
        component: lazy(() => import('src/views/WorkOrder/Keep'))
      }
    ]
  },
  {
    path: '/game_cfg',
    text: '游戏配置',
    icon: 'SettingOutlined',
    exact: true,
    pms: 'Gm.menu_game_config',
    routes: [
      {
        path: '/game_cfg/platform',
        text: '游戏平台',
        exact: true,
        pms: 'Gm.game_plat_list',
        component: lazy(() => import('src/views/GameConfig/Platform'))
      },
      {
        path: '/game_cfg/api',
        text: '游戏接口',
        exact: true,
        pms: 'Gm.game_api_list',
        component: lazy(() => import('src/views/GameConfig/Api'))
      },
      {
        path: '/game_cfg/server_relation',
        text: '区服关联',
        exact: true,
        pms: 'Gm.game_server_list',
        component: lazy(() => import('src/views/GameConfig/ServerRelation'))
      },
      {
        path: '/game_cfg/field/exclusive',
        text: '专属字段',
        exact: true,
        pms: 'Gm.game_exclusive_list',
        component: lazy(() => import('src/views/GameConfig/Field/Exclusive'))
      },
      {
        path: '/game_cfg/field/common',
        text: '通用字段',
        exact: true,
        pms: 'Gm.sys_field_list',
        component: lazy(() => import('src/views/GameConfig/Field/Common'))
      },
      {
        path: '/game_cfg/field/mapping',
        text: '字段映射',
        exact: true,
        pms: 'Gm.game_field_list',
        component: lazy(() => import('src/views/GameConfig/Field/Mapping'))
      }
    ]
  },
  {
    path: '/permit',
    text: '权限管理',
    icon: 'SafetyCertificateOutlined',
    exact: true,
    pms: 'Gm.menu_permit_manager',
    routes: [
      {
        path: '/permit/list',
        text: '权限列表',
        exact: true,
        pms: 'Gm.permit_list',
        component: lazy(() => import('src/views/Permit/List'))
      },
      {
        path: '/permit/role',
        text: '角色列表',
        exact: true,
        pms: 'Gm.permit_role_list',
        component: lazy(() => import('src/views/Permit/Role'))
      },
      {
        path: '/permit/authorize',
        text: '用户授权',
        exact: true,
        pms: 'Gm.permit_user_list',
        component: lazy(() => import('src/views/Permit/Authorize'))
      }
    ]
  },
  {
    path: '/log',
    text: 'GM操作日志',
    icon: 'UnorderedListOutlined',
    exact: true,
    pms: 'Gm.gm_log_list',
    component: lazy(() => import('src/views/Log'))
  },
  {
    path: '/debugger',
    text: '接口调试',
    icon: 'CodeOutlined',
    exact: true,
    component: lazy(() => import('src/components/Debugger'))
  }
]

export default routes
