import http, { ResultData } from 'src/utilities/http'
import { Key } from 'react'

/**
 * 字段数据类型枚举
 *
 * @export
 * @enum {number}
 */
export enum EnumDataType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  Array = 'array',
  Object = 'object',
  ArrayObject = 'array_object'
}

/**
 * 字段数据类型键值对
 */
export const FieldDataTypes: { [k: string]: string } = {
  string: '文本',
  number: '数字',
  date: '时间',
  boolean: '布尔',
  array: '数组',
  object: '对象',
  array_object: '数组对象'
}

/**
 * 字段属性
 *
 * @interface FieldProps
 */
interface FieldProps {
  field: string
  name: string
  type: EnumDataType
}

/**
 * 专属字段属性
 *
 * @export
 * @interface ExclusiveFieldProps
 * @extends {FieldProps}
 */
export interface ExclusiveFieldProps extends FieldProps {
  id: number
  gid: string
  children?: FieldProps[]
}

/**
 * 游戏专属字段配置管理
 */
const exclusiveField = {
  /**
   * 获取字段配置列表
   *
   * @param {Key} gid
   * @returns
   */
  list(gid: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      http
        .get('/game/exclusive', {
          params: {
            gid
          }
        })
        .then((result) => {
          if (result.code === 1) {
            let data = result.data || []
            data.forEach((el: any) => {
              if (el.children) {
                el.children = JSON.parse(el.children)
              }
            })
          }
          resolve(result)
        })
        .catch(reject)
    })
  },

  /**
   * 获取字段配置详情
   *
   * @param {Key} id
   * @returns
   */
  get(id: Key) {
    return new Promise<ResultData>((resolve, reject) => {
      http
        .get(`/game/exclusive/${id}`)
        .then((result) => {
          if (result.code === 1 && result.data.children) {
            result.data.children = JSON.parse(result.data.children)
          }
          resolve(result)
        })
        .catch(reject)
    })
  },

  /**
   * 新增字段配置
   *
   * @param {*} data - 表单数据
   * @returns
   */
  add(data: any) {
    return http.post('/game/exclusive', data)
  },

  /**
   * 修改字段配置
   *
   * @param {*} data - 表单数据
   * @returns
   */
  update(data: any) {
    let id = data.id
    delete data.id
    return http.put(`/game/exclusive/${id}`, data)
  },

  /**
   * 删除字段配置
   *
   * @param {Key} id - ID
   * @returns
   */
  delete(id: Key) {
    return http.delete(`/game/exclusive/${id}`)
  }
}

export default exclusiveField
