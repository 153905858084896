import http from 'src/utilities/http'
import { Key } from 'react'

/**
 * 自动任务属性
 */
export interface AutoTaskProps {
  id: number // 游戏接口ID
  gid: number // 游戏ID
  pid: number // 平台ID
  interval: number // 任务执行间隔时间（秒）
}

/**
 * 自动任务
 */
const autoTask = {
  /**
   * 获取自动任务列表
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @returns
   */
  list(gid: Key, pid: Key) {
    return http.get(`/game/mapping/${gid}/${pid}`)
  },

  /**
   * 保存自动任务
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @param {AutoTaskProps[]} data - 表单数据
   * @returns
   */
  save(gid: Key, pid: Key, data: AutoTaskProps[]) {
    return http.put(`/game/mapping/save/${gid}/${pid}`, data)
  },

  /**
   * 执行自动任务
   *
   * @param {Key} gid - 游戏ID
   * @param {Key} pid - 平台ID
   * @param {string} name - 任务名称
   * @returns
   */
  exec(gid: Key, pid: Key, name: string) {
    return http.put(`/game/mapping/reload/${gid}/${pid}/${name}`)
  }
}

export default autoTask
